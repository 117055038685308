<template>
    <div class="grid grid-cols-3 gap-x-2">
        <div>
            <label for="min" class="block text-sm font-medium text-gray-700" v-if="!hideLabel"><slot name="labelMin">Minimum</slot></label>
            <div :class="{ 'mt-1' : !hideLabel }">
                <input
                    v-model="element.min"
                    type="text"
                    name="min"
                    id="min"
                    placeholder="Min."
                    class="read-only:bg-slate-50 read-only:cursor-not-allowed read-only:text-slate-500 read-only:border-slate-200 read-only:shadow-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                >
            </div>
        </div>
        <div>
            <label for="max" class="block text-sm font-medium text-gray-700" v-if="!hideLabel"><slot name="labelMax">Maximum</slot></label>
            <div :class="{ 'mt-1' : !hideLabel }">
                <input
                    v-model="element.max"
                    type="text"
                    name="max"
                    id="max"
                    placeholder="Max."
                    class="read-only:bg-slate-50 read-only:cursor-not-allowed read-only:text-slate-500 read-only:border-slate-200 read-only:shadow-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                >
            </div>
        </div>
        <div>
            <label for="step" class="block text-sm font-medium text-gray-700" v-if="!hideLabel"><slot name="labelStep">Schritte</slot></label>
            <div :class="{ 'mt-1' : !hideLabel }">
                <input
                    v-model="element.step"
                    type="text"
                    name="step"
                    id="step"
                    placeholder="Step"
                    class="read-only:bg-slate-50 read-only:cursor-not-allowed read-only:text-slate-500 read-only:border-slate-200 read-only:shadow-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    preview: { type: Boolean, default: false, },
    editable: { type: Boolean, default: true, },
    multiline: { type: Boolean, default: true, },
    hideLabel: { type: Boolean, default: false, },
    readonlyId: { type: Boolean, default: true, },
    element: { type: Object },
  },
}
</script>

<style>

</style>
