<script setup>
import { Link } from '@inertiajs/vue3';
defineProps({
    funnel: Object,
    response: Object,
});
const isEmpty = function(answer) {
    return answer.elements && Object.keys(answer.elements).length == 0
}
</script>

<template>
    <div class="lg:px-8 response-show-wrap w-full">
        <div class="flex flex-col">
            <div class="overflow-hidden rounded-lg bg-white shadow">
                <div class="bg-gray-50 px-4 py-4 sm:px-6 flex justify-between">
                    <h5 class="text-xl">
                        {{$t('Response')}} <span class="text-gray-400">#{{response.id}}</span>
                    </h5>
                    <span class="text-gray-400">
                        {{$t('at')}}
                        <span class="text-black">{{ $moment(response.created_at).format('lll') }}</span>
                    </span>
                </div>
                <div class="border-t border-gray-200">
                    <!-- https://tailwindui.com/components/application-ui/data-display/description-lists -->
                    <dl>
                        <div
                            v-for="answer, answerIndex in response.data.answers" :key="answerIndex"
                            :class="answerIndex % 2 == 0 ? 'bg-gray-50' : 'bg-white'"
                            class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    {{$t('Step')}} #{{(answerIndex+1)}}
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <ul v-if="!isEmpty(answer)">
                                        <li v-for="el,idx in answer.elements" :key="answerIndex+'-'+idx" class="mb-3">
                                            <div><strong>Eingabe:</strong> {{$t(el.type)}}</div>
                                            <template v-if="el.type">
                                                <template v-if="el.type == 'upload'">
                                                    <a v-if="el.answer" :href="el.answer" target="_blank" class="text-indigo-600">{{$t('Download File')}}</a>
                                                    <span v-else class="text-gray-500">{{$t('No file')}}</span>
                                                </template>
                                                <div v-else><strong>Antwort:</strong> {{el.answer}}</div>
                                            </template>
                                        </li>
                                    </ul>
                                    <div v-else class="text-gray-400">
                                        {{$t('No response')}}
                                    </div>
                                </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    </div>
</template>
