<script setup>
import { useForm } from '@inertiajs/vue3';
import JetButton from '@jsResource/Jetstream/Button.vue';
import { onMounted } from 'vue';
import { trans } from 'laravel-vue-i18n';

const props = defineProps({
    template: {
        type: Object,
        default: null
    },
})

const form = useForm({
    description: '',
    name: trans('My new funnel'),
    template_id: null,
});

onMounted(() => {
    if (props.template) {
        form.template_id = props.template.id;
        form.name = props.template.name;
        form.description = props.template.description;
    }
});

const submit = () => {
    form.post(route('funnels.store'), {
        onFinish: () => form.reset('name', 'description'),
    });
};
</script>

<template>
  <div class="mt-10 sm:mt-0">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <h3 class="text-lg font-medium leading-6 text-gray-900">{{$t('Funnel details')}}</h3>
          <p class="mt-1 text-sm text-gray-600">{{$t('You are able to update these settings whenever you need to')}}.</p>
        </div>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2">
        <form @submit.prevent="submit">
          <div class="bg-white border-gray-200 border sm:rounded-md overflow-hidden">
            <!-- display template gradient -->
            <template v-if="template">
                <div class="h-3" :class="'bg-gradient-to-r from-'+(template.template_gradient_from ?? 'indigo')+'-500 via-'+(template.template_gradient_over ?? 'purple')+'-500 to-'+(template.template_gradient_to ?? 'orange')+'-500'"></div>
            </template>
            <div class="px-4 py-5 bg-white sm:p-6">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-6">
                  <label for="name" class="block text-sm font-medium text-gray-700">{{$t('Name')}}</label>
                  <input type="text" v-model="form.name" name="name" id="name" autocomplete="name" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                </div>

                <div class="col-span-6 sm:col-span-6">
                    <label for="description" class="block text-sm font-medium text-gray-700"> {{$t('What is the goal?')}} </label>
                    <div class="mt-1">
                    <textarea id="description" name="description" v-model="form.description" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md" :placeholder="$t('This is my new funnel')" />
                    </div>
                    <p class="mt-2 text-sm text-gray-500">{{$t('Brief description for your own notes')}}.</p>
                </div>

              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 flex justify-between sm:px-6">
                <div class="pt-2">
                    <span class="mt-1 text-sm text-gray-600" v-if="form.isDirty">{{$t('There are unsaved changes')}}.</span>
                </div>
                <JetButton class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                  {{form.id ? $t('Save') : $t('Create')}}
                </JetButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
