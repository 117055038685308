import { defineStore } from 'pinia'
import api from '@jsResource/helper/api'

import { useFunnelsStore } from '@jsResource/stores/funnels'

// steps is the name of the store. It is unique across your application
// and will appear in devtools
export const useStepsStore = defineStore('steps', {
    // a function that returns a fresh state
    state: () => ({
        index: [],
        indexNonResults: [],
        indexResults: [],
        indexLoaded: false,
        indexLoading: false,
        show: {},
        showLoaded: false,
        showLoading: false,
        filter: {}
    }),
    getters: {
        // // getters receive the state as first parameter
        // doubleCount: (state) => state.counter * 2,
        // // use getters in other getters
        // doubleCountPlusOne() {
        // return this.doubleCount + 1
        // },
    },
    actions: {
        async getIndex( { funnel }) {
            // `this` is the store instance
            this.indexLoading = true;
            let resp = await api.get(`/funnels/${funnel}/steps`)
            this.index = resp.data.data
            let respNonResults = await api.get(`/funnels/${funnel}/resultNonSteps`)
            this.indexNonResults = respNonResults.data.data
            let respResults = await api.get(`/funnels/${funnel}/resultSteps`)
            this.indexResults = respResults.data.data
            this.indexLoading = false;
            this.indexLoaded = true;
        },
        async getShow({ funnel, step }) {
            // `this` is the store instance
            this.showLoading = true;
            let resp = await api.get(`/funnels/${funnel}/steps/${step}`)
            this.show = resp.data.data
            this.showLoading = false;
            this.showLoaded = true;
        },
        async createNew( { funnel, step = null }) {
            // `this` is the store instance
            this.indexLoading = true;
            let resp = await api.post(`/funnels/${funnel}/steps`, step)
            this.getIndex( { funnel: funnel });
            return resp.data.data;
        },
        async addIntegration( { funnel, step, integration }) {
            // `this` is the store instance
            this.indexLoading = true;
            let resp = await api.post(`/funnels/${funnel}/steps/${step}/integrations`, { integration_id: integration.id })
            this.getShow( { funnel: funnel, step: step });
            const funnelsStore = useFunnelsStore();
            funnelsStore.getShow({funnel: funnel});
            this.indexLoading = false;
        },
        async updateIntegration( { funnel, step, integration, data }) {
            // `this` is the store instance
            this.indexLoading = true;
            let resp = await api.patch(`/funnels/${funnel}/steps/${step}/integrations/${integration}`, data)
            this.getShow( { funnel: funnel, step: step });
            this.indexLoading = false;
        },
        async deleteIntegration( { funnel, step, integration }) {
            // `this` is the store instance
            this.indexLoading = true;
            let resp = await api.delete(`/funnels/${funnel}/steps/${step}/integrations/${integration.id}`)
            this.getShow( { funnel: funnel, step: step });
            this.indexLoading = false;
        },
        async saveShow( {funnel, step}) {
            let resp = await api.patch(`/funnels/${funnel}/steps/${step}`, this.show)
            this.getIndex( { funnel: funnel });
            return step
        },
        async deleteShow( {funnel, step}) {
            let resp = await api.delete(`/funnels/${funnel}/steps/${step}`)
        },
        async duplicate( {funnel, step}) {
            let resp = await api.post(`/funnels/${funnel}/steps/${step}/replicate`)
            this.getIndex( { funnel: funnel });
        },
        async reorder( {funnel, steps} ) {
            this.indexLoading = true;
            this.indexLoaded = false;
            let ids = steps.map(item => item.id);
            let resp = await api.patch(`/funnels/${funnel}/reorder`, { steps: ids });
            this.index = resp.data.data
            this.indexLoading = false;
            this.indexLoaded = true;
        }
    },
})
