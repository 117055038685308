import { mapStores } from 'pinia'
import { useBuilderStore } from '@jsResource/stores/builder'
import { useStepsStore } from '@jsResource/stores/steps'
import str from "../../helper/str"
//const builderStore = useBuilderStore()
//const { hoveringIndex, showSettingsTab, dragging, editModule } = storeToRefs(builderStore)

export default {
    emits: ['removeElement'],
    computed: {
        // note we are not passing an array, just one store after the other
        // each store will be accessible as its id + 'Store'
        ...mapStores(useBuilderStore, useStepsStore)
    },
    methods: {
        editElement(element, index) {
            this.builderStore.browserTab = 3;
            this.builderStore.editModule = element;
        },
        duplicateElement(element, index) {
            let newArray = []
            let newElement = JSON.parse(JSON.stringify(this.stepsStore.show.layout[index]));
            newElement.id = str.randStr(15);
            newElement.name = newElement.type+"-"+str.randStr(7);
            this.stepsStore.show.layout = newArray.concat(
                this.stepsStore.show.layout.slice(0, index),
                newElement,
                this.stepsStore.show.layout.slice(index)
            );
        },
        removeElement(element, index) {
            let elementType = element.type;
            if (confirm("Wirklich " + elementType + " unwiderruflich löschen?")) {
                this.stepsStore.show.layout.splice(index, 1); // 1 means number of elements to remove
                this.builderStore.browserTab = 1;
                this.builderStore.editModule = null;
                this.$emit('removeElement', element, index);

                this.$notify({
                    group: "builder",
                    title: "Gelöscht",
                    text: "Modul vom Typ " + elementType + " wurde gelöscht"
                }, 3000) // 2s
            }
        },
    }
}
