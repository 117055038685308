import { defineStore } from 'pinia'

// builder is the name of the store. It is unique across your application
// and will appear in devtools
export const usePersistStore = defineStore('persist', {
    // a function that returns a fresh state
    persist: true,
    state: () => ({
        previewSize: "mobile",      // mobile, tablet, desktop
        expertMode: false,
    }),
    getters: {},
    actions: {},
})
