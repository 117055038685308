<template>
    <div class="row">
        <Picker :data="emojiIndex" set="twitter" @select="showEmoji" :i18n="i18n" />
    </div>
</template>

<script>
import i18n from './emojiLang/de.json'

// Import data/twitter.json to reduce size, all.json contains data for
// all emoji sets.
import data from "emoji-mart-vue-fast/data/all.json";

// Import default CSS
import "emoji-mart-vue-fast/css/emoji-mart.css";

// Vue 3, import components from `/src`:
import { Picker, EmojiIndex } from "emoji-mart-vue-fast/src";

// Create emoji data index.
// We can change it (for example, filter by category) before passing to the component.
let emojiIndex = new EmojiIndex(data);

export default {
    props: {
        preview: { type: Boolean, default: false },
        editable: { type: Boolean, default: true },
        modelValue: {
            type: String,
            default: "",
        },
    },

    computed: {
        i18n() {
            return i18n;
        },
    },

    components: {
        Picker,
    },

    data() {
        return {
            emojiIndex: emojiIndex,
        };
    },

    methods: {
        showEmoji(emoji) {
            this.$emit("update:modelValue", emoji);
        },
    },

    emits: ["update:modelValue"],
};
</script>

<style>
.row {
    display: flex;
}
.row > * {
    margin: auto;
}
</style>
