<template>
    <div>
        <SwitchGroup as="div" class="flex items-center">
            <Switch @update:modelValue="inputCb" :modelValue="modelValue" :class="[modelValue ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']">
                <span aria-hidden="true" :class="[modelValue ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
                <span class="text-sm font-medium text-gray-900">Volle Breite?</span>
                <span class="text-sm text-gray-500"> (automatische Höhe)</span>
            </SwitchLabel>
        </SwitchGroup>
    </div>
</template>

<script setup>
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
</script>

<script>
export default {
  props: {
    preview: { type: Boolean, default: false, },
    editable: { type: Boolean, default: true, },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    inputCb(newValue) {
        this.$emit('update:modelValue', newValue)
    }
  },

  emits: ['update:modelValue'],
}
</script>

<style>

</style>
