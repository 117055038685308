<script setup>
import { Link } from '@inertiajs/vue3';
</script>
<template>
    <div class="text-sm">
        {{ $t('This funnel is accessible under the Domain') }}:
        <span class="font-mono"><strong>{{ integration.data.domain }}</strong><span class="text-gray-500">/funnel/</span>{{ funnel.slug }}</span>
        <br><br>
        {{ $t('To change the domain go to') }}
        <Link :href="$route('integrations.show', {integration: integration.id})" class="text-indigo-500">{{ $t('Integration Settings') }}</Link>
    </div>
</template>

<script>
import partialMixin from "./partialMixin"
export default {
    mixins: [partialMixin]
}
</script>

<style>

</style>
