<script setup>
import { computed, ref } from 'vue';
import { router } from '@inertiajs/vue3';
import { useForm } from '@inertiajs/vue3';
import { Switch } from '@headlessui/vue'
import JetFormSection from '@jsResource/Jetstream/FormSection.vue';

const props = defineProps({
    funnel: Object,
});
const codeSettings = ref({
    disableHash: false,
    disableScrolling: false,
    slug: props.funnel.slug,
});
const sourceCode = computed(() => {
    let disableScrollingText = codeSettings.value.disableScrolling ? " disable-scrolling" : "";
    let disableHashText = codeSettings.value.disableHash ? " disable-hash" : "";
    return {
        head: "&lt;script src=\"https://www.funnerix.com/js/widget_loader.js?v=2.0\" defer&gt;&lt;/script&gt;"
             +"&lt;script&gt;window.onload = (ev) => { loadFunnerix(); };&lt;/script&gt;",
        body: "&lt;funnerix-widget"+disableScrollingText+""+disableHashText+" slug=\""+props.funnel.slug+"\"&gt;&lt;/funnerix-widget&gt;"
    };
});

</script>

<template>
    <JetFormSection>
        <template #title>
            {{$t('Integrate your funnel')}}
        </template>

        <template #description>
            {{$t('Embed your funnel e.g. in your website')}}
        </template>

        <template #form>
            <div class="col-span-6">
                <h5>{{$t('Web component')}}</h5>

                <p class="mt-3 text-sm mb-4">{{ $t('Simply integrate your Funnerix funnel by placing a code in the head and where you want the funnel to appear in the body') }}.</p>

                <div>
                    <label for="comment" class="block text-sm font-medium leading-6 text-gray-900">1. Source Code: <code>&lt;HEAD&gt;</code></label>
                    <div class="mt-2">
                        <textarea
                            rows="3"
                            v-html="sourceCode.head"
                            readonly name="code_head"
                            id="code_head"
                            class="block w-full font-mono bg-gray-100 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        ></textarea>
                    </div>
                </div>


                <div class="mt-6">
                    <label for="comment" class="block text-sm font-medium leading-6 text-gray-900">2. Source Code: <code>&lt;BODY&gt;</code></label>
                    <div class="mt-2">
                        <textarea
                            rows="2"
                            v-html="sourceCode.body"
                            readonly name="code_body"
                            id="code_body"
                            class="block w-full font-mono bg-gray-100 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        ></textarea>
                    </div>
                </div>

                <div class="mt-6">
                    <div class="grid grid-cols-2 gap-4">
                        <div class="flex">
                            <Switch v-model="codeSettings.disableHash" :class="[codeSettings.disableHash ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']">
                                <span class="sr-only">Disable Hash?</span>
                                <span aria-hidden="true" :class="[codeSettings.disableHash ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                            </Switch>
                            <div class="text-sm pl-3">Disable Hash in URL?</div>
                        </div>

                        <div class="flex">
                            <Switch v-model="codeSettings.disableScrolling" :class="[codeSettings.disableScrolling ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']">
                                <span class="sr-only">Disable Scrolling?</span>
                                <span aria-hidden="true" :class="[codeSettings.disableScrolling ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                            </Switch>
                            <div class="text-sm pl-3">Disable Scrolling?</div>
                        </div>
                    </div>
                </div>

            </div>
        </template>

        <template #actions>
            <!-- <JetActionMessage :on="form.recentlySuccessful" class="mr-3">
                {{$t('Saved')}}.
            </JetActionMessage>

            <JetButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                {{$t('Save')}}
            </JetButton> -->
        </template>
    </JetFormSection>
</template>
