<template>
    <div>
        <label for="header" class="block text-sm font-medium text-gray-700">{{ $t('Header') }}</label>
        <div class="mt-1">
            <input
                :value="modelValue"
                @input="inputCb"
                type="text"
                name="header"
                id="header"
                placeholder="Your text goes here"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
            >
        </div>
    </div>
</template>

<script>
export default {
  props: {
    preview: { type: Boolean, default: false, },
    editable: { type: Boolean, default: true, },
    modelValue: {
      type: String,
      default: '',
    },
  },

  methods: {
    inputCb(event) {
        this.$emit('update:modelValue', event.target.value)
    }
  },

  emits: ['update:modelValue'],
}
</script>

<style>

</style>
