<script setup>
import { onMounted, ref } from 'vue';
import { router } from '@inertiajs/vue3';
import { useForm } from '@inertiajs/vue3';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import JetButton from '@jsResource/Jetstream/Button.vue';
import JetFormSection from '@jsResource/Jetstream/FormSection.vue';
import JetInput from '@jsResource/Jetstream/Input.vue';
import JetInputError from '@jsResource/Jetstream/InputError.vue';
import JetLabel from '@jsResource/Jetstream/Label.vue';
import JetActionMessage from '@jsResource/Jetstream/ActionMessage.vue';
import JetSecondaryButton from '@jsResource/Jetstream/SecondaryButton.vue';
import IconBrowserButton from '@jsResource/Funnerix/Modules/shared/IconBrowserButton.vue';
import IconBrowserModal from "../../Funnerix/Modules/shared/IconBrowserModal.vue"
import { DotsHorizontalIcon } from "@heroicons/vue/outline"
import * as outlineIcons  from '@heroicons/vue/outline'
import * as solidIcons  from '@heroicons/vue/solid'
const allHeroIcons = { outline: outlineIcons, solid: solidIcons };

const props = defineProps({
    funnel: Object,
});

// tailwind color names
const colors = [
    'slate','gray','zinc','neutral','stone','red','orange','amber','yellow','lime','green','emerald','teal','cyan','sky','blue','indigo','violet','purple','fuchsia','pink','rose',
];

const form = useForm({
    _method: 'PUT',
    is_template: props.funnel.is_template,
    template_icon: props.funnel.template_icon,
    template_gradient_from: props.funnel.template_gradient_from,
    template_gradient_over: props.funnel.template_gradient_over,
    template_gradient_to: props.funnel.template_gradient_to,
    template_category_id: props.funnel.template_category_id,
});

// onmounted
onMounted(() => {
    if (form.template_icon === null || !form.template_icon.iconSet) {
        form.template_icon = {iconSet: 'heroicon', iconType: 'outline', iconName: 'AcademicCapIcon'};
    }
    if (form.template_gradient_from === null) { form.template_gradient_from = 'indigo'; }
    if (form.template_gradient_over === null) { form.template_gradient_over = 'cyan'; }
    if (form.template_gradient_to === null) { form.template_gradient_to = 'sky'; }
});

const selectIcon = (template_icon) => {
    console.log(template_icon);
    form.template_icon = template_icon.native;
};
const updateFunnelInformation = () => {
    form.post(route('funnels.update',{funnel:props.funnel}), {
        errorBag: 'updateFunnelInformation',
        preserveScroll: true,
        onSuccess: () => updateSuccessCb(),
    });
};
const updateSuccessCb = () => {
    console.log('updateSuccessCb');
};
const selectedIconCb = (template_icon) => {
    form.template_icon = template_icon;
};
</script>

<template>
    <JetFormSection @submitted="updateFunnelInformation">
        <template #title>
            {{$t('Template settings')}}
        </template>

        <template #description>
            {{$t('Provide your funnel as a template for others to use')}}
        </template>

        <template #form>
            <div class="col-span-6 sm:col-span-4">
                <h5>{{$t('Settings for template usage')}}</h5>
                <p class="mt-3 text-sm mb-4">{{$t('You can provide your funnel as a template for others to use. This means that everyone can clone this funnel and use it as a starting point for their own funnels.')}}</p>

                <SwitchGroup as="div" class="flex items-center" id="template_icon_switch">
                    <Switch v-model="form.is_template" :class="[form.is_template ? 'bg-orange-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2']">
                        <span aria-hidden="true" :class="[form.is_template ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                    </Switch>
                    <SwitchLabel as="span" class="ml-3">
                        <span class="text-sm font-medium" :class="form.is_template ? 'text-orange-500' : ''">{{$t('This funnel is a global template') }}. {{$t('Everyone can clone this funnel') }}.</span>
                    </SwitchLabel>
                </SwitchGroup>
            </div>

            <template v-if="form.is_template">
                <div class="col-span-8 sm:col-span-4">
                    <JetLabel for="template_icon" :value="$t('Template Browser Icon (optional)')" />
                    <icon-browser-button id="template_icon" @selected="selectedIconCb" :item="form.template_icon" :full-width="true" />
                    <icon-browser-modal :api-media-url="null" />
                </div>

                <div class="col-span-8 sm:col-span-4 grid grid-cols-3 gap-4">
                    <select id="gradient-from" name="gradient-from" v-model="form.template_gradient_from" class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <option disabled>{{ $t('Color') }}</option>
                        <option v-for="color in colors" :key="color">{{color}}</option>
                    </select>
                    <select id="gradient-over" name="gradient-over" v-model="form.template_gradient_over" class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <option disabled>{{ $t('Color') }}</option>
                        <option v-for="color in colors" :key="color">{{color}}</option>
                    </select>
                    <select id="gradient-to" name="gradient-to" v-model="form.template_gradient_to" class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <option disabled>{{ $t('Color') }}</option>
                        <option v-for="color in colors" :key="color">{{color}}</option>
                    </select>
                </div>

                <div class="col-span-6 sm:col-span-4">
                    <div class="w-full h-12 text-center pt-4 rounded-xl" :class="'bg-gradient-to-r from-'+form.template_gradient_from+'-500 via-'+form.template_gradient_over+'-500 to-'+form.template_gradient_to+'-500'">
                        <component v-if="form.template_icon.iconSet == 'heroicon' && allHeroIcons[form.template_icon.iconType] && allHeroIcons[form.template_icon.iconType][form.template_icon.iconName]"
                            class="text-white w-6 h-6 inline" :is="allHeroIcons[form.template_icon.iconType][form.template_icon.iconName]"></component>
                        <span v-else-if="form.template_icon.iconSet == 'emoji'">{{form.template_icon.iconName}}</span>
                        <DotsHorizontalIcon v-else class="icon-dots h-5 w-5 text-gray-400" />
                    </div>
                </div>

                <div class="col-span-6 sm:col-span-4">
                    <select class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="form.template_category_id">
                        <option disabled>{{ $t('Please choose a category...') }}</option>
                        <option v-for="category in $page.props.funnelCategories" :key="category.id" :value="category.id">{{$t(category.name)}} [#{{category.id}}]</option>
                    </select>
                </div>
            </template>
        </template>

        <template #actions>
            <JetActionMessage :on="form.recentlySuccessful" class="mr-3">
                {{$t('Saved')}}.
            </JetActionMessage>

            <JetButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                {{$t('Save')}}
            </JetButton>
        </template>
    </JetFormSection>
</template>
