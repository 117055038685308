<script setup>
import { marked } from 'marked';
import { ref } from 'vue'
import { Switch } from '@headlessui/vue'
import { router } from '@inertiajs/vue3';
import { useForm } from '@inertiajs/vue3';
import JetButton from '@jsResource/Jetstream/Button.vue';
import JetFormSection from '@jsResource/Jetstream/FormSection.vue';
import JetInput from '@jsResource/Jetstream/Input.vue';
import JetInputError from '@jsResource/Jetstream/InputError.vue';
import JetLabel from '@jsResource/Jetstream/Label.vue';
import JetActionMessage from '@jsResource/Jetstream/ActionMessage.vue';
import JetSecondaryButton from '@jsResource/Jetstream/SecondaryButton.vue';
import FunnerixBuilderIntegrationsbrowser from '@jsResource/Funnerix/Builder/Integrationsbrowser.vue';

const props = defineProps({
    funnel: Object,
    variables: Array,
});

const directives = ref([
   { name: 'IF', highlight: 'bg-green-50 text-green-600 ring-green-500/10', },
   { name: 'ENDIF', highlight: 'bg-green-50 text-green-600 ring-green-500/10', },
   { name: 'GPT', highlight: 'bg-pink-50 text-pink-600 ring-pink-500/10', },
]);

const previewTransform = (text) => {
    if (!text) return '';
    // do new lines <br>
    text = text.replace(/\n/g, '<br>');
    // replace %variable% with <span class="...">variable</span>
    text = text.replace(/%(.+?)%/g, '<span class="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10"><span>$1</span></span>');
    // replace #IF(...)) with <span class="...">#IF($1)</span>
    text = text.replace(/#IF\((.+?)\)\)/g, '<span class="mt-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"><span>IF($1)</span></span>');
    // replace #IF(...) with <span class="...">#IF($1)</span>
    text = text.replace(/#IF\((.+?)\)/g, '<span class="mt-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">IF($1)</span>');
    // replace #ENDIF with <span class="...">#ENDIF</span>
    text = text.replace(/#ENDIF/g, '<span class="mb-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"><span>ENDIF</span></span>');
    // replace #GPT(...) with <span class="...">#GPT($1)</span>
    text = text.replace(/#GPT\((.+?)\)/g, '<span class="inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10"><span>GPT($1)</span></span>');
    return marked.parse(text);
}

const form = useForm({
    _method: 'PUT',
    emails_lead_text: props.funnel.emails_lead_text,
    emails_lead_subject: props.funnel.emails_lead_subject,
    emails_lead_bcc: props.funnel.emails_lead_bcc,
    emails_lead_enable: props.funnel.emails_lead_enable,
    emails_lead_delay_minutes: props.funnel.emails_lead_delay_minutes,
    emails_notify_team_email: props.funnel.emails_notify_team_email,
    emails_notify_email_enabled: props.funnel.emails_notify_email_enabled,
    emails_notify_me: props.funnel.emails_notify_me,
    emails_notify_team_owner: props.funnel.emails_notify_team_owner,
    emails_notify_all_team_users: props.funnel.emails_notify_all_team_users,
});

const updateFunnelInformation = () => {
    form.post(route('funnels.update',{funnel:props.funnel}), {
        errorBag: 'updateFunnelInformation',
        preserveScroll: true,
        //onSuccess: () => clearPhotoFileInput(),
    });
};
</script>

<template>
    <JetFormSection @submitted="updateFunnelInformation">
        <template #title>
            {{$t('Notifications and Emails')}}
        </template>

        <template #description>
            {{$t('Define, which notifications you like to use')}}
        </template>

        <template #form>
            <div class="col-span-6">
                <h5>{{$t('E-Mail to you, your team and other recipients')}}</h5>

                <div class="grid grid-cols-2 gap-x-4">
                    <div class="flex mt-2 mb-4">
                        <Switch v-model="form.emails_notify_email_enabled" :class="[form.emails_notify_email_enabled ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']">
                            <span class="sr-only">{{$t('Send notification when there\'s a new response')}}?</span>
                            <span aria-hidden="true" :class="[form.emails_notify_email_enabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                        </Switch>
                        <div class="text-sm pl-3">{{$t('Enable notifications')}}</div>
                    </div>

                    <div v-if="form.emails_notify_email_enabled">
                        <div class="flex mt-2 mb-4">
                            <Switch v-model="form.emails_notify_me" :class="[form.emails_notify_me ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']">
                                <span class="sr-only">{{$t('Notify me')}}?</span>
                                <span aria-hidden="true" :class="[form.emails_notify_me ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                            </Switch>
                            <div class="text-sm pl-3">{{$t('Notify me')}}?</div>
                        </div>
                        <div class="flex mt-2 mb-4">
                            <Switch v-model="form.emails_notify_team_owner" :class="[form.emails_notify_team_owner ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']">
                                <span class="sr-only">{{$t('Notify team owner')}}?</span>
                                <span aria-hidden="true" :class="[form.emails_notify_team_owner ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                            </Switch>
                            <div class="text-sm pl-3">{{$t('Notify team owner')}}?</div>
                        </div>
                        <div class="flex mt-2 mb-4">
                            <Switch v-model="form.emails_notify_team_email" :class="[form.emails_notify_team_email ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']">
                                <span class="sr-only">{{$t('Notify team email address')}}?</span>
                                <span aria-hidden="true" :class="[form.emails_notify_team_email ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                            </Switch>
                            <div class="text-sm pl-3">{{$t('Notify team email address')}}?</div>
                        </div>
                        <div class="flex mt-2 mb-4">
                            <Switch v-model="form.emails_notify_all_team_users" :class="[form.emails_notify_all_team_users ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']">
                                <span class="sr-only">{{$t('Notify all team users')}}?</span>
                                <span aria-hidden="true" :class="[form.emails_notify_all_team_users ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                            </Switch>
                            <div class="text-sm pl-3">{{$t('Notify all team users')}}?</div>
                        </div>
                    </div>
                </div>

                <h5>{{$t('E-Mails to the new lead contact')}}</h5>

                <div class="grid grid-cols-2 gap-x-4">
                    <div class="flex mt-2 mb-4">
                        <Switch v-model="form.emails_lead_enable" :class="[form.emails_lead_enable ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']">
                            <span class="sr-only">{{$t('Send mail to lead contact')}}?</span>
                            <span aria-hidden="true" :class="[form.emails_lead_enable ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                        </Switch>
                        <div class="text-sm pl-3">{{$t('Send mail to lead contact')}}</div>
                    </div>

                    <!-- emails_lead_delay_minutes -->
                    <div class="flex mt-2 mb-4" v-if="form.emails_lead_enable">
                        <JetLabel for="emails_lead_delay_minutes" :value="$t('Delay in minutes')" />
                        <JetInput
                            id="emails_lead_delay_minutes"
                            v-model.number="form.emails_lead_delay_minutes"
                            type="number"
                            class="mt-1 block w-full"
                            autocomplete="name"
                        />
                        <JetInputError :message="form.errors.emails_lead_delay_minutes" class="mt-2" />
                    </div>
                </div>

                <template v-if="form.emails_lead_enable">
                    <JetLabel for="name" :value="$t('Email Subject')" />
                    <JetInput
                        id="name"
                        v-model="form.emails_lead_subject"
                        type="text"
                        class="mt-1 mb-2 block w-full"
                        autocomplete="name"
                    />
                    <JetInputError :message="form.errors.emails_lead_subject" class="mb-4" />

                    <JetLabel for="name" :value="$t('BCC (optional)')" />
                    <JetInput
                        id="name"
                        v-model="form.emails_lead_bcc"
                        type="text"
                        class="mt-1 mb-2 block w-full"
                        autocomplete="name"
                    />
                    <JetInputError :message="form.errors.emails_lead_bcc" class="mb-4" />

                    <JetLabel for="emails_lead_text" :value="$t('Email notification to lead contact')" />
                    <div class="mt-1">
                        <textarea
                            v-model="form.emails_lead_text"
                            rows="7"
                            id="emails_lead_text"
                            name="emails_lead_text"
                            ref="emails_lead_text"
                            :placeholder="$t('Email, the lead contact receives')+'. '+$t('Leave empty for default')"
                            class="shadow-sm font-mono focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"></textarea>

                        <p class="mt-2 text-sm text-gray-500">You can use the following content variables. Highlighted ones are used by you:</p>
                        <template v-for="variable in variables" :key="variable">
                            <span
                            :class="[form.emails_lead_text && form.emails_lead_text.includes('%'+variable+'%') ? 'bg-indigo-50 text-indigo-600 ring-indigo-500/10' : '', 'bg-gray-50 text-gray-600 ring-gray-500/10']"
                            class="inline-flex transition-all items-center mr-2 rounded-md px-2 py-1 text-xs font-ring-1 ring-inset">{{ variable }}</span>
                        </template>
                        <p class="mt-2 text-sm text-gray-500">You can use the following directives (e.g. #IF('...')). Highlighted ones are used by you:</p>
                        <template v-for="directive in directives" :key="directive">
                            <span
                            :class="[form.emails_lead_text && form.emails_lead_text.includes('#'+directive.name) ? directive.higlight : '', 'bg-gray-50 text-gray-600 ring-gray-500/10']"
                            class="inline-flex transition-all items-center mr-2 rounded-md px-2 py-1 text-xs font-ring-1 ring-inset">{{ directive.name }}</span>
                        </template>

                        <br><hr><br>
                        <JetButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                            {{$t('Save all')}}
                        </JetButton>

                        <p class="text-sm text-gray-500 mt-8">Preview of your email with markdown:</p>
                        <div class="block w-full rounded-md border-0 py-1.5 text-gray-900 pl-3 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                            <div contenteditable spellcheck="false" v-html="previewTransform(form.emails_lead_text)" />
                        </div>
                    </div>
                </template>
            </div>
        </template>

        <template #actions>
            <JetActionMessage :on="form.recentlySuccessful" class="mr-3">
                {{$t('Saved')}}.
            </JetActionMessage>

            <JetButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                {{$t('Save')}}
            </JetButton>
        </template>
    </JetFormSection>
</template>
