<template>
    <div>
        <label for="steps-range" class="block text-sm font-medium text-gray-700" v-if="!hideLabel"><slot name="label">Abstand</slot></label>
        <input id="steps-range" type="range" @input="inputRangeCb" min="0" :max="modes.length-1" :value="currentRangeValue" step="1"
        class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer">
    </div>
</template>

<script>
export default {
  props: {
    preview: { type: Boolean, default: false, },
    editable: { type: Boolean, default: true, },
    hideLabel: { type: Boolean, default: false },
    modes: { type: Array, default: (['xs','s','m','l','xl','xxl']), },
    modelValue: {
      type: String,
      default: '',
    },
  },

  computed: {
    currentRangeValue() {
        return this.modes.indexOf(this.modelValue) ?? Math.round(this.modes.length / 2)
    }
  },

  methods: {
    inputRangeCb(event) {
        let value = event.target.value
        if (this.modes[value]) {
            this.$emit('update:modelValue', this.modes[value])
        }
    },
  },

  emits: ['update:modelValue'],
}
</script>

<style>

</style>
