<template>
    <div class="space-y-3" id="integrations">
        <h5 class="border-b text-gray-600 border-gray-100 text-sm">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="inline-block w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            {{ $t('Integrations') }}
        </h5>

        <div class="mt-2 text-sm font-bold">{{ $t('Installed Integrations') }}</div>
        <ul v-if="installedIntegrations && installedIntegrations.length > 0">
            <li v-for="installedIntegration in installedIntegrations" :key="installedIntegration.id" class="mb-3">
                <h5 class="border-b text-gray-900 border-gray-100 text-sm flex mb-2 pb-1 justify-between">
                    <div class="flex">
                        <img class="h-6 w-6 flex-shrink-0 rounded-full mr-2 bg-gray-300" :src="`/img/generic/${installedIntegration.type}_logo.png`" :alt="installedIntegration.name">
                        {{$t('Settings for')}} {{installedIntegration.name}}
                    </div>
                    <button @click="removeIntegration(installedIntegration)" class="rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        {{ $t('Remove') }}
                    </button>
                </h5>
                <div class="shadow rounded p-4 ml-4">
                    <component :is="`${installedIntegration.type}-partial`" :integration="installedIntegration" :model="model">
                        <span class="text-sm text-gray-400">{{ $t('Nothing to set up here') }}</span>
                    </component>
                </div>
            </li>
        </ul>
        <div v-else class="text-sm text-gray-400"><em>keine</em></div>

        <div class="mt-2 text-sm font-bold">{{$t('Available Integrations')}}</div>
        <ul v-if="availableIntegrations && availableIntegrations.length > 0">
            <li v-for="availableIntegration in availableIntegrations" :key="availableIntegration.id" class="mb-3">
                <button @click="addIntegration(availableIntegration)" type="button" class="block w-full items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    {{$t('Add')}}
                    <em>{{availableIntegration.name}}</em>
                    {{$t(model == 'steps' ? 'to this step' : 'to this funnel')}}...
                </button>
            </li>
        </ul>
        <div v-else class="text-sm text-gray-400"><em>keine</em></div>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useBuilderStore } from '@jsResource/stores/builder'
import { useFunnelsStore } from '@jsResource/stores/funnels'
import { useStepsStore } from '@jsResource/stores/steps'
import { router } from '@inertiajs/vue3';
import ActivecampaignPartial from "./IntegrationPartials/ActivecampaignPartial.vue"
import MailerlitePartial from "./IntegrationPartials/MailerlitePartial.vue"
import MetaPixelPartial from "./IntegrationPartials/MetaPixelPartial.vue"
import CustomDomainPartial from "./IntegrationPartials/CustomDomainPartial.vue"
import WebhookPartial from "./IntegrationPartials/WebhookPartial.vue"
import OpenAIPartial from "./IntegrationPartials/OpenAIPartial.vue"
export default {
    props: {
        availableIntegrations: { type: Array, default: () => ([]) },
        installedIntegrations: { type: Array, default: () => ([]) },
        model: { type: String, default: 'steps' },
    },
    components: {
        ActivecampaignPartial,
        MailerlitePartial,
        WebhookPartial,
        'domain-partial' : CustomDomainPartial,
        'openai-partial' : OpenAIPartial,
        'meta_pixel-partial': MetaPixelPartial
    },
    computed: {
        // note we are not passing an array, just one store after the other
        // each store will be accessible as its id + 'Store'
        ...mapStores(useBuilderStore),
        ...mapStores(useFunnelsStore),
        ...mapStores(useStepsStore),
        theStore() {
            if (this.model == "steps") {
                return this.stepsStore;
            } else {
                return this.funnelsStore;
            }
        },
        funnel() {
            return this.funnelsStore.show;
        }
    },
    methods: {
        async addIntegration(integration) {
            if (this.model == "steps") {
                await this.theStore.addIntegration({
                    funnel: this.funnelsStore.show.id,
                    step: this.stepsStore.show.id,
                    integration
                });
            } else {
                await this.theStore.addIntegration({
                    funnel: this.funnelsStore.show.id,
                    integration
                });
                router.get(route('funnels.edit', {funnel: this.funnelsStore.show.id}) + '#integrations');
            }
        },
        async removeIntegration(integration) {
            if (this.model == "steps") {
                await this.theStore.deleteIntegration({
                    funnel: this.funnelsStore.show.id,
                    step: this.stepsStore.show.id,
                    integration
                });
            } else {
                await this.theStore.deleteIntegration({
                    funnel: this.funnelsStore.show.id,
                    integration
                });
                router.get(route('funnels.edit', {funnel: this.funnelsStore.show.id}) + '#integrations');
            }
        }
    }
}
</script>
