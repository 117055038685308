<template>
    <div>
        <label for="text" class="block text-sm font-medium text-gray-700" v-if="!hideLabel"><slot name="label">{{$t('Animation')}}</slot></label>
        <span class="isolate inline-flex rounded-md" :class="{ 'mt-1' : !hideLabel }">
            <button @click="inputToggleCb('animate-bounce')"
                type="button"
                :class="modelValue == 'animate-bounce' ? 'border-indigo-300 bg-white text-indigo-700 hover:bg-indigo-50' : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'"
                class="relative inline-flex items-center rounded-l-md border px-4 py-2 text-sm font-medium focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            >
                <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4 mr-1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" />
                </svg> -->
                {{$t('Bounce')}}
            </button>
            <button @click="inputToggleCb('animate-shake')"
                type="button"
                :class="modelValue == 'animate-shake' ?  'border-indigo-300 bg-white text-indigo-700 hover:bg-indigo-50' : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'"
                class="relative -ml-px inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            >
                <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4 mr-1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg> -->
                {{$t('Shake')}}
            </button>
            <button @click="inputToggleCb('animate-zoom')"
                type="button"
                :class="modelValue == 'animate-zoom' ?  'border-indigo-300 bg-white text-indigo-700 hover:bg-indigo-50' : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'"
                class="relative -ml-px inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            >
                <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4 mr-1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg> -->
                {{$t('Zoom')}}
            </button>
            <button @click="inputToggleCb('animate-pulse')"
                type="button"
                :class="modelValue == 'animate-pulse' ? 'border-indigo-300 bg-white text-indigo-700 hover:bg-indigo-50' : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'"
                class="relative -ml-px inline-flex items-center rounded-r-md border px-4 py-2 text-sm font-medium focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            >
                <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4 mr-1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />
                </svg> -->
                {{$t('Pulse')}}
            </button>
        </span>
    </div>
</template>

<script>
export default {
  props: {
    preview: { type: Boolean, default: false, },
    editable: { type: Boolean, default: true, },
    hideLabel: { type: Boolean, default: false },
    modelValue: {
      type: String,
      default: '',
    },
  },

  methods: {
    inputToggleCb(value) {
        if (this.modelValue == value) {
            value = null;
        }
        this.$emit('update:modelValue', value)
    }
  },

  emits: ['update:modelValue'],
}
</script>

<style>

</style>
