<script setup>
import { ref } from 'vue';
import { router } from '@inertiajs/vue3';
import { useForm } from '@inertiajs/vue3';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import JetButton from '@jsResource/Jetstream/Button.vue';
import JetFormSection from '@jsResource/Jetstream/FormSection.vue';
import JetInput from '@jsResource/Jetstream/Input.vue';
import JetInputError from '@jsResource/Jetstream/InputError.vue';
import JetLabel from '@jsResource/Jetstream/Label.vue';
import JetActionMessage from '@jsResource/Jetstream/ActionMessage.vue';
import JetSecondaryButton from '@jsResource/Jetstream/SecondaryButton.vue';

const props = defineProps({
    countdown: Object,
});

const form = useForm({
    _method: 'PUT',
    bg_upload: null,
});

const photoPreview = ref(null);
const photoInput = ref(null);

const selectNewPhoto = () => {
    photoInput.value.click();
};

const updatePhotoPreview = () => {
    const photo = photoInput.value.files[0];

    if (! photo) return;

    const reader = new FileReader();

    reader.onload = (e) => {
        photoPreview.value = e.target.result;
    };

    reader.readAsDataURL(photo);

    if (photoInput.value) {
        form.bg_upload = photoInput.value.files[0];
    }

    form.post(route('countdowns.updateImages',{countdown:props.countdown}), {
        errorBag: 'updateFunnelInformation',
        preserveScroll: true,
        onSuccess: () => clearPhotoFileInput(),
    });

};

const deletePhoto = () => {
    router.delete(route('current-user-photo.destroy'), {
        preserveScroll: true,
        onSuccess: () => {
            photoPreview.value = null;
            clearPhotoFileInput();
        },
    });
};

const clearPhotoFileInput = () => {
    if (photoInput.value?.value) {
        photoInput.value.value = null;
    }
};
</script>

<template>
    <!-- Profile Photo -->
    <div class="col-span-6 sm:col-span-4">
        <!-- Profile Photo File Input -->
        <input
            ref="photoInput"
            type="file"
            class="hidden"
            @change="updatePhotoPreview"
        >

        <JetLabel for="bg_upload" :value="'Background Image'" />

        <!-- Current Profile Photo -->
        <div v-show="! photoPreview" class="mt-2">
            <img :src="countdown.bg_image_url" v-if="countdown.bg_image_url" :alt="'BG '+countdown.name" class="rounded-full h-20 w-20 object-cover">
        </div>

        <!-- New Profile Photo Preview -->
        <div v-show="photoPreview" class="mt-2">
            <span
                class="block rounded-full w-20 h-20 bg-cover bg-no-repeat bg-center"
                :style="'background-image: url(\'' + photoPreview + '\');'"
            />
        </div>

        <JetSecondaryButton class="mt-2 mr-2" type="button" @click.prevent="selectNewPhoto">
            {{$t('Select A New Photo')}}
        </JetSecondaryButton>

        <JetSecondaryButton
            v-if="countdown.bg_photo_path"
            type="button"
            class="mt-2"
            @click.prevent="deletePhoto"
        >
            {{ $t('Remove Photo') }}
        </JetSecondaryButton>

        <JetInputError :message="form.errors.photo" class="mt-2" />
    </div>
</template>
