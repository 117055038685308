<template>
    <div class="pb-10 px-0.5 border-2 transition-all" :class="{ 'border-purple-500' : dragging, 'border-transparent' : !dragging }">
        <div class="absolute w-full top-0 right-0 left-0 bg-purple-600 transition-all"
            v-show="dragging && show.layout && show.layout.length == 0">
            <div class="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
            <div class="pr-16 sm:px-16 sm:text-center">
                <p class="font-medium text-white">
                    <span class="md:hidden">Ziehen und loslassen...</span>
                    <span class="hidden md:inline">Ziehe das Modul und lass es hier los.</span>
                </p>
            </div>
            </div>
        </div>

        <modules-global-header @clicked="headerClickedCb" :funnel="funnel" :step="show" :preview="!dragging" :disabled="dragging"></modules-global-header>

        <div class="text-center mt-12" v-if="!indexLoading && show.layout && show.layout.length == 0">
            <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
            </svg>
            <h3 class="mt-2 text-sm font-medium text-gray-900">Keine Module</h3>
            <p class="mt-1 text-sm text-gray-500">Klicke links und ziehe ein Modul hier rüber.</p>
        </div>


        <draggable v-if="show && show.layout"
            class="dragArea2 previewer-modules"
            :list="show.layout"
            group="modules"
            ghost-class="moving-element"
            @start="dragging = true"
            @end="dragging = false"
            direction="vertical"
            :animation="150"
            handle=".dragger"
            item-key="id"
        >
            <template #item="{ element, index }">
                <div @mouseenter="hovering(index)" @mouseleave="hovering(null)"
                    :class="[
                        editModule === element ? 'border-orange-600 hover:border-orange-500' : (hoveringIndex == index ? 'border-indigo-500 hover:border-blue-500' : 'hover:border-gray-400 border-transparent')
                    ]"
                    class="previewer-item border relative">
                        <div v-show="editModule === element" class="w-2 h-2 absolute left-0 top-0 -ml-1 -mt-1 bg-white border-orange-500 border">&nbsp;</div>
                        <div v-show="editModule === element" class="w-2 h-2 absolute left-0 bottom-0 -ml-1 -mb-1 bg-white border-orange-500 border">&nbsp;</div>
                        <div v-show="editModule === element" class="w-2 h-2 absolute right-0 top-0 -mr-1 -mt-1 bg-white border-orange-500 border">&nbsp;</div>
                        <div v-show="editModule === element" class="w-2 h-2 absolute right-0 bottom-0 -mr-1 -mb-1 bg-white border-orange-500 border">&nbsp;</div>
                        <div @click="editElement(element, index)" class="absolute z-20 bg-white mt-4 left-4 cursor-pointer hover:text-indigo-500 dragger-wrapper shadow-lg rounded-lg px-3 py-3 flex justify-end gap-x-1">
                            <div class="icon hover:text-indigo-500 design-edit">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            </div>
                        </div>
                        <div class="absolute z-20 mt-4 right-4 dragger-wrapper shadow-lg bg-white rounded-lg px-3 py-3 flex justify-end gap-x-1">
                            <div class="icon hover:text-indigo-500 design-edit cursor-pointer" @click="duplicateElement(element, index)">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                                </svg>
                            </div>
                            <div class="icon hover:text-indigo-500 delete-me cursor-pointer" @click="removeElement(element, index)">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                            </div>
                            <div class="icon hover:text-indigo-500 cursor-grab dragger">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
                                </svg>
                            </div>
                        </div>
                        <component
                            :initialTabindex="index*100"
                            class="previewer-module"
                            :preview="!dragging"
                            :disabled="dragging"
                            :is="'modules-partials-'+element.type"
                            @iconClicked.native="iconClickedCb"
                            :element="element"
                            :funnel="funnel" />

                </div>
            </template>
        </draggable>
    </div>
</template>

<script setup>
import { useFunnelsStore } from '@jsResource/stores/funnels'
import { useStepsStore } from '@jsResource/stores/steps'
import { useBuilderStore } from '@jsResource/stores/builder'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
const funnelsStore = useFunnelsStore()
const funnel = computed( () => funnelsStore.show );
const stepsStore = useStepsStore()
const builderStore = useBuilderStore()
const { editModule, hoveringIndex, showSettingsTab, dragging, showIconbrowserModal, iconbrowserItem, iconbrowserTab } = storeToRefs(builderStore)
stepsStore.getIndex({funnel : route().params.funnel});
const { index, indexLoaded, indexLoading, show, filter } = storeToRefs(stepsStore)
const layout = computed( () => show.layout );
function hovering(index) { builderStore.hoveringBuilderIndex = index }
</script>

<script>
import { mapStores } from 'pinia'
import builderMixin from "./builderMixin";
import draggable from "vuedraggable";
import { useBuilderStore } from '@jsResource/stores/builder'
export default {
    components: {
        draggable
    },
    computed: {
        ...mapStores(useBuilderStore)
    },
    mixins: [builderMixin],
    emits: [ 'iconClicked'],
    methods: {
        headerClickedCb(element) {
            console.log("Header clicked",element);
            this.builderStore.browserTab = 2;
            this.builderStore.showIconbrowserModal = false;
            this.builderStore.showSettingsTab = false;
        },
        iconClickedCb(element, tabsInModal = [1,2,3]) {
            console.log("Icon click. Tabs to display ",tabsInModal);
            this.builderStore.iconbrowserItem = element;
            this.builderStore.iconbrowserModalTabs = tabsInModal;
            this.builderStore.showIconbrowserModal = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.moving-card {
    @apply opacity-50 bg-gray-100 border border-blue-500;
}
.previewer-modules .previewer-item .dragger-wrapper { transition: all 0.3s ease; opacity: 0 }
.previewer-modules .previewer-item:hover .dragger-wrapper { opacity: 1.0 }
.dragger-wrapper .icon { transition: all 0.3s ease; opacity: 0.3; }
.dragger-wrapper:hover .icon { opacity: 1.0; }
</style>
