<template>
    <button
        @click="iconbrowserItem = item; showIconbrowserModal = true;"
        class="icon-browser-button inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    >
        <slot>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-gray-400 w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
            </svg>
        </slot>
    </button>
</template>

<script setup>
defineProps({
    item: Object,
});
import { ref, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { DotsHorizontalIcon } from "@heroicons/vue/outline"
import { SelectorIcon, ShieldCheckIcon,EmojiHappyIcon } from '@heroicons/vue/outline'

import * as outlineIcons  from '@heroicons/vue/outline'
import * as solidIcons  from '@heroicons/vue/solid'
const allHeroIcons = { outline: outlineIcons, solid: solidIcons };

import { useBuilderStore } from '@jsResource/stores/builder'
const builderStore = useBuilderStore()
const { showIconbrowserModal, iconbrowserItem, iconbrowserTab } = storeToRefs(builderStore)
</script>
<script>
export default {
    emits: ['selected'],
    methods: {
        selected(iconSet, iconType, iconName, icon = null) {
            this.open = false;
            this.$emit('selected', { iconSet, iconType, iconName, icon });
        },
        selectEmoji(emoji) {
            this.selected('emoji', emoji.id, emoji.native, emoji.unified);
        }
    }
}
</script>
