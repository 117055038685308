function userPlanIndex() {
    return window.$funnerix && typeof window.$funnerix.planindex !== "undefined" ? window.$funnerix.planindex : false
}
function funnerixPlans() {
    return window.$funnerix && window.$funnerix.plans ? window.$funnerix.plans : {}
}
function funnerixObject() {
    return window.$funnerix ? window.$funnerix : {}
}
function funnerixVersion() {
    return window.$funnerix && window.$funnerix.version ? window.$funnerix.version : 'n/A'
}
function funnerixLang() {
    return window.$funnerix && window.$funnerix.lang ? window.$funnerix.lang : 'de'
}

function getUserFeature (featureString, planIndex = false) {
    let thePlanIndex = planIndex ? planIndex : userPlanIndex();
    const keys = featureString.split('.');
    let result = funnerixPlans();
    for (const key of keys) {
        if (result[key] === undefined) {
            return undefined; // or throw an Error if you prefer
        }
        result = result[key];
    }
    return result[thePlanIndex] ?? false;
}

export {
    userPlanIndex,
    funnerixPlans,
    funnerixObject,
    funnerixVersion,
    funnerixLang,
    getUserFeature
}
