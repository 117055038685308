<template>
    <div>
        <label for="text" class="block text-sm font-medium text-gray-700" v-if="!hideLabel"><slot name="label">Text</slot></label>
        <div :class="{ 'mt-1' : !hideLabel }">
            <div v-if="multiline" class="shadow-sm p-2 border focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                <funnerix-tiptap
                    :editable="true"
                    :modelValue="modelValue"
                    @update:modelValue="$emit('update:modelValue', $event)"
                    :preview="true"
                    name="text"
                    id="text"
                    />
            </div>
            <!-- <textarea v-if="multiline"
                :value="modelValue"
                @input="inputCb"
                rows="5"
                name="text"
                id="text"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"></textarea> -->
            <input v-else
                :value="modelValue"
                @input="inputCb"
                :type="type"
                :name="name"
                :id="id"
                :placeholder="placeholder"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
            >
        </div>
    </div>
</template>

<script>
import Tiptap from "../Tiptap.vue"
export default {
  components: {
    'funnerix-tiptap' : Tiptap,
  },
  props: {
    preview: { type: Boolean, default: false, },
    editable: { type: Boolean, default: true, },
    multiline: { type: Boolean, default: true, },
    hideLabel: { type: Boolean, default: false, },
    placeholder: { type: String, default: 'Text'},
    name: { type: String, default: 'text'},
    modelValue: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    id: {
      type: String,
      default: 'text',
    },
  },

  methods: {
    inputCb(event) {
        this.$emit('update:modelValue', event.target.value)
    }
  },

  emits: ['update:modelValue'],
}
</script>

<style>

</style>
