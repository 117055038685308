<template>
  <div class="ml-4 mr-4 mt-4 space-y-3">
    <template v-for="category in categories" :key="category.key">
        <h5 class="border-b text-gray-600 border-gray-100 text-sm">{{$t(category.name)}}</h5>
        <div class="bg-white shadow overflow-hidden sm:rounded-md">
            <draggable v-if="modules[category.key]"
                tag="ul"
                :sort="false"
                :list="modules[category.key]"
                :group="{ name: 'modules', pull: 'clone', put: false }"
                :clone="cloneItem"
                ghost-class="bg-gray-600"
                draggable=".enabled-module"
                @change="log"
                @start="dragging = true; showSettingsTab = false;"
                @end="dragging = false"
                class="divide-y divide-gray-200"
                item-key="id"
            >
                <template #item="{ element, index }">
                    <div class="flex px-2 py-1 cursor-move transition-all bg-white hover:bg-gray-100 hover:text-indigo-500" :class="isDisabled(element) === false ? 'enabled-module' : 'disabled-module cursor-not-allowed'" v-if="availableModules[element]">
                        <div v-html="availableModules[element].svg" class="mr-2"></div>
                        <span class="text-sm" :class="isDisabled(element) === false ? 'text-gray-900' : 'text-gray-400'">
                            {{availableModules[element].title}}
                            <UpgradeBadge :showMe="isDisabled(element)" />
                        </span>
                        <div class="ml-auto" v-if="isDisabled(element)">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-400">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636" />
                            </svg>
                        </div>
                        <div class="ml-auto handle" v-else>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                            </svg>
                        </div>
                    </div>
                    <div v-else class="flex px-2 py-4 sm:px-6 cursor-not-allowed text-gray-400">
                        {{$t('Unknown')}} ({{element}})
                    </div>
                </template>
            </draggable>
            <div v-else>{{$t('Empty category')}}</div>
        </div>
    </template>
  </div>
</template>

<script setup>
import availableModules from "../../conf/builderModules"
import { useBuilderStore } from '@jsResource/stores/builder'
import { storeToRefs } from 'pinia'
import UpgradeBadge from "../Base/UpgradeBadge.vue";
const builderStore = useBuilderStore()
const { showSettingsTab, dragging } = storeToRefs(builderStore)
</script>

<script>
import draggable from "vuedraggable";
import str from "../../helper/str"
import {getUserFeature} from "../../helper/planHelper.js";
export default {
    components: {
        draggable
    },
    data: () => ({
        definitions: [],
        categories: [
            {name: 'Text modules', key: 'text' },
            {name: 'Input modules', key: 'input' },
            {name: 'Control modules', key: 'control' },
        ],
        modules: {
            text: [
                'heading',
                'text',
                'html',
                'image',
                'hr',
                //'accordion',
                'social',
                'list',
            ],
            input: [
                'multiple',
                'imagegrid',
                'rate',
                'slider',
                'input',
                'textarea',
            ],
            control: [
                'btnnext',
                'upload',
                'form',
            ]
        }
    }),
    async mounted() {
        await this.loadAllDefaults()
    },
    methods: {
        isDisabled(module) {
            if (module === 'html' && getUserFeature('builder.html') === false) {
                return true
            } else if (module === 'upload' && getUserFeature('builder.upload') === false) {
                return true
            } else if (module === 'social' && getUserFeature('builder.social') === false) {
                return true
            } else if (module === 'rate' && getUserFeature('builder.rate') === false) {
                return true
            }
            return false
        },
        async loadAllDefaults() {
            const moduleTypes = ['text', 'input', 'control'];
            for (const type of moduleTypes) {
                for (const module of this.modules[type]) {
                    this.definitions.push({
                        module: module,
                        obj: await this.loadModuleDefaults(module)
                    });
                }
            }
        },
        log: function(evt) {
            console.log("Browser");
            console.log(evt);
        },
        cloneItem(module) {
            let id = str.randStr(6);
            // find it
            let def = this.definitions.find((def) => def.module === module);
            if (def) {
                def.obj.id = id;
                return JSON.parse(JSON.stringify(def.obj));
            } else {
                console.error("Module not found", module);
            }
        },
        async loadModuleDefaults(moduleName) {
            let moduleContent = await import(/* vite-ignore */ `../../conf/bootstraps/${moduleName}.js`)
            let bootstrapMod = JSON.parse(JSON.stringify(moduleContent.default));
            let content = {...bootstrapMod};
            return JSON.parse(JSON.stringify( {
                id: str.randStr(15),
                type: moduleName,
                name: moduleName+'-'+str.randStr(7),
                content: content
            } ));
        },
    }
}
</script>
