<template>
    <div>
        <div class="flex items-center">
            <div v-if="!hideInput">
                <label for="colorSelected" class="block text-sm font-medium text-gray-700 mb-1" v-if="!hideLabel"><slot name="label">Farbe wählen</slot></label>
                <input id="colorSelected" type="text" placeholder="Farbe eingeben"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                    @click="inputClickCb"
                    v-model="colorSelected">
            </div>
            <div :class="hideInput ? '' : 'ml-3 mt-8'">
                <button type="button" @click="isOpen = !isOpen"
                    class="w-9 h-9 rounded-full focus:outline-none focus:shadow-outline inline-flex p-1.5 shadow"
                    :style="`background: ${colorSelected}; color: ${!colorSelected||colorSelected=='#ffffff'?'#999':'#fff'};`"
                >
                    <slot name="icon">
                        <svg class="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M15.584 10.001L13.998 8.417 5.903 16.512 5.374 18.626 7.488 18.097z"/><path d="M4.03,15.758l-1,4c-0.086,0.341,0.015,0.701,0.263,0.949C3.482,20.896,3.738,21,4,21c0.081,0,0.162-0.01,0.242-0.03l4-1 c0.176-0.044,0.337-0.135,0.465-0.263l8.292-8.292l1.294,1.292l1.414-1.414l-1.294-1.292L21,7.414 c0.378-0.378,0.586-0.88,0.586-1.414S21.378,4.964,21,4.586L19.414,3c-0.756-0.756-2.072-0.756-2.828,0l-2.589,2.589l-1.298-1.296 l-1.414,1.414l1.298,1.296l-8.29,8.29C4.165,15.421,4.074,15.582,4.03,15.758z M5.903,16.512l8.095-8.095l1.586,1.584 l-8.096,8.096l-2.114,0.529L5.903,16.512z"/></svg>
                    </slot>
                </button>

                <div v-show="isOpen" @click.away="isOpen = false" x-transition:enter="transition ease-out duration-100 transform"
                    x-transition:enter-start="opacity-0 scale-95" x-transition:enter-end="opacity-100 scale-100"
                    x-transition:leave="transition ease-in duration-75 transform"
                    x-transition:leave-start="opacity-100 scale-100" x-transition:leave-end="opacity-0 scale-95"
                    :class="position == 'bottom' ? 'origin-bottom-right mt-2' : 'origin-top-right mb-2'"
                    class="absolute z-20 right-2 w-4/5 rounded-md shadow-lg">
                    <div class="rounded-md bg-white shadow-xs px-4 py-3">
                        <div class="flex flex-wrap -mx-2">
                        <template v-for="(color, index) in colors" :key="index">
                            <div
                                class="px-2"
                            >
                                <template v-if="colorSelected === color">
                                    <div
                                        class="w-8 h-8 inline-flex rounded-full cursor-pointer border-4 border-white"
                                        :style="`background: ${color}; box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);`"
                                    >
                                        <svg v-if="color == null" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-500">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </template>

                                <template v-if="colorSelected != color">
                                    <div
                                        @click="colorSelected = color"
                                        @keydown.enter="colorSelected = color"
                                        role="checkbox"
                                            tabindex="0"
                                            :aria-checked="colorSelected"
                                        class="w-8 h-8 shadow-sm inline-flex rounded-full cursor-pointer border-4 focus:outline-none focus:shadow-outline"
                                        :class="color == '#ffffff' ? 'border-gray-200' : 'border-white' "
                                        :style="`background: ${color};`"
                                    >
                                        <svg v-if="color == null" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-500">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    preview: { type: Boolean, default: false, },
    editable: { type: Boolean, default: true, },
    position: { type: String, default: 'bottom', },
    showTransparent: { type: Boolean, default: true },
    hideInput: { type: Boolean, default: false },
    hideLabel: { type: Boolean, default: false },
    modelValue: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    isOpen: false,
    colors: [
        null,
        '#ffffff',
        '#000000',
        '#64748b',
        '#71717a',
        '#737373',
        '#78716c',
        '#ef4444',
        '#f97316',
        '#f59e0b',
        '#eab308',
        '#84cc16',
        '#22c55e',
        '#10b981',
        '#14b8a6',
        '#06b6d4',
        '#6366f1',
    ],
  }),

  computed: {
    colorSelected: {
        get() {
            return this.modelValue && this.modelValue != null ? this.modelValue : '';
        },
        set(value) {
            if (!value || value == null) {
                value = '';
            }
            this.$emit('update:modelValue', value)
        }
    }
  },

  methods: {
    inputClickCb() {
        if (this.colorSelected == '') {
            this.isOpen = true;
        }
    }
  },

  emits: ['update:modelValue'],
}
</script>

<style>

</style>
