<template>
    <div class="space-y-6 sm:space-y-5">
        <modules-helper-full-width v-model="theContent.fullWidth"></modules-helper-full-width>
        <modules-helper-size-switcher v-if="!theContent.fullWidth" v-model="theContent.size"></modules-helper-size-switcher>
        <modules-helper-uploader :element="element" :api-media-url="'/api/funnels/'+funnel.id+'/media'"></modules-helper-uploader>
        <div class="grid grid-cols-2 gap-x-2">
            <modules-helper-colors v-model="element.content.style.backgroundColor"><template #label>{{$t('Background Color')}}</template></modules-helper-colors>
            <modules-helper-colors v-model="element.content.style.color"><template #label>{{$t('Color')}}</template></modules-helper-colors>
        </div>
        <modules-helper-id-name :element="element"></modules-helper-id-name>
    </div>
</template>

<script>
import modulesMixin from "../modulesMixin";
export default {
    mixins: [ modulesMixin ]
}
</script>

<style>

</style>
