<template>
    <div class="mt-2 ">
        <div role="status" class="animate-pulse bg-purple-500 rounded h-1 absolute top-0 left-0 right-0" v-show="indexLoading"></div>
        <div class="steps-wrapper block md:flex xl:block">
            <div class="normal-steps-wrapper md:border-gray-200 md:border-r xl:border-0 space-y-3 flex-1 lg:pb-4">
                <div class="border-t text-xs bg-gray-100 pl-2 flex justify-between">
                    <span class="pt-1 pb-1 flex justify-between flex-1 md:flex-auto">
                        <span class="self-center">{{$t('Funnel pages')}}</span>
                        <span class="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                            <template v-if="!loading">
                                {{indexResults.length}} / {{getUserFeature('funnels.max_steps')}}
                            </template>
                            <LoadingDots :size="2" :gap="1" dotClass="bg-gray-300" class="p-1" v-else></LoadingDots>
                        </span>
                    </span>
                    <button @click="createNewStep(false)" class="hidden md:block xl:hidden rounded-full bg-white px-2.5 py-1 text-sm font-semibold text-gray-900 mr-2 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        <svg class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                        </svg>
                    </button>
                </div>
                <draggable v-if="indexNonResults"
                    class="ml-2 mr-2 stepDrag list-group transition-all transition-gpu space-y-3 gap-x-0 gap-y-2 md:space-y-0 md:gap-x-2 xl:gap-x-0 xl:space-y-3 block md:grid md:grid-cols-5 xl:block "
                    :class="[
                        stepDragging ? 'outline-indigo-500 outline' : '',
                        indexLoading ? 'opacity-40' : ''
                    ]"
                    :list="indexNonResults"
                    group="steps"
                    ghost-class="opacity-0"
                    :animation="150"
                    handle=".dragger"
                    @change="reorderCb"
                    @start="stepDragging = true"
                    @end="stepDragging = false"
                    item-key="id"
                >
                    <template #item="{ element, index }">
                        <Link preserve-scroll @click="navigateTo(element) "
                              :href="$route('steps.show', { funnel: route().params.funnel, step: element.id })"
                              @contextmenu.prevent="rightClickStep(element)"
                              class="steps-item relative block w-full border-2 rounded-lg pt-2 pb-2 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              :class="[
                                show.id == element.id ? 'border-indigo-500 hover:border-indigo-400' : 'hover:border-gray-400 border-gray-100',
                                element.is_result_step ? 'border-gray-400' : ''
                              ]"
                        >
                            <div :class="!editModeSteps ? 'dragger-wrapper transition-opacity' : ''" class="absolute right-1 top-1 pt-2 pr-2">
                                <button @click.prevent type="button" v-show="!editModeSteps" class="dragger ml-auto mr-auto inline-flex cursor-grab items-center rounded-full border border-transparent bg-white p-1 text-gray-500 hover:text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
                                    </svg>
                                </button>
                                <button @click.prevent="duplicateCb(element)" v-show="editModeSteps" type="button" class="mr-auto inline-flex cursor-pointer items-center rounded-full border border-transparent bg-indigo-600 p-1 text-white hover:text-indigo-200 shadow-sm hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ml-0.5">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                                    </svg>
                                </button>
                            </div>

                            <div class="removal-wrapper absolute left-1 top-1 pt-2 pl-2">
                                <button @click.prevent="deleteCb(element)" v-show="editModeSteps" type="button" class="ml-auto mr-auto inline-flex cursor-pointer items-center rounded-full border border-transparent bg-red-600 p-1 text-white hover:text-red-200 shadow-sm hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                                    </svg>
                                </button>
                            </div>

                            <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto h-8 w-8 text-gray-400 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                            </svg>
                            <span class="block text-sm font-medium text-gray-900"> {{element.slug}} </span>
                        </Link>
                    </template>
                </draggable>
                <div class="ml-2 mr-2 md:hidden xl:block">
                    <button v-if="canCreateNewFunnelStep" @click="createNewStep(false)" class="block w-full border-2 border-gray-300 border-dashed rounded-lg pt-2 pb-2 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto h-8 w-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" />
                        </svg>
                        <span class="mt-2 block text-sm font-medium text-gray-900"> {{$t('Create a new page')}} </span>
                    </button>
                    <Link v-else :href="$route('me.showBilling')" class="block w-full border-2 border-gray-100 border-dashed rounded-lg pt-2 pb-2 text-center hover:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mx-auto h-8 w-8 text-gray-200">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636" />
                        </svg>
                        <span class="mt-2 block text-sm font-medium text-gray-500"> {{$t('Upgrade your plan')}} </span>
                    </Link>
                </div>
            </div>

            <div class="result-steps-wrapper space-y-3 ">
                <div class="border-t text-xs bg-gray-100 pt-1 pb-1 pl-2 flex justify-between">
                    <span class="self-center">{{$t('Result Pages')}}</span>
                    <span class="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10" v-if="!loading">
                        <template v-if="!loading">
                                {{indexResults.length}} / {{getUserFeature('funnels.max_result_steps')}}
                        </template>
                        <LoadingDots :size="2" :gap="1" dotClass="bg-gray-300" class="p-1" v-else></LoadingDots>
                    </span>
                </div>
                <div class="ml-2 mr-2 space-y-3">
                    <template v-if="indexResults.length > 0">
                        <Link preserve-scroll v-for="element in indexResults" :key="element.id" :href="$route('steps.show', { funnel: route().params.funnel, step: element.id })" @click="resetUi"
                            class="block relative w-full border-2 rounded-lg pt-2 pb-2 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            :class="[
                                show.id == element.id ? 'border-orange-500 hover:border-orange-300' : 'border-gray-100 hover:border-gray-400',
                            ]"
                        >
                            <div class="removal-wrapper absolute left-1 top-1 pt-2 pl-2">
                                <button @click.prevent="deleteCb(element)" v-show="editModeSteps" type="button" class="ml-auto mr-auto inline-flex cursor-pointer items-center rounded-full border border-transparent bg-red-600 p-1 text-white hover:text-red-200 shadow-sm hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                                    </svg>
                                </button>
                            </div>

                            <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto h-8 w-8 text-gray-400 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                            </svg>
                            <span class="block text-sm font-medium text-gray-900"> {{element.slug}} </span>
                        </Link>
                    </template>
                    <div v-else class="block w-full border-2 border-orange-300 border-dashed rounded-lg pt-2 pb-2 text-center hover:border-orange-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span class="mt-2 mb-2 w-3/5 ml-auto mr-auto block text-sm font-medium text-orange-500">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                            </svg>
                            {{$t('There is no results page yet')}}
                        </span>
                    </div>
                    <button v-if="canCreateNewResultStep" @click="createNewStep(true)" class="block w-full border-2 border-gray-300 border-dashed rounded-lg pt-2 pb-2 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto h-8 w-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" />
                        </svg>
                        <span class="mt-2 block text-sm font-medium text-gray-900"> {{$t('Create a new results page')}} </span>
                    </button>
                    <Link v-else :href="$route('me.showBilling')" class="block w-full border-2 border-gray-100 border-dashed rounded-lg pt-2 pb-2 text-center hover:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mx-auto h-8 w-8 text-gray-200">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636" />
                        </svg>
                        <span class="mt-2 block text-sm font-medium text-gray-500"> {{$t('Upgrade your plan')}} </span>
                    </Link>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useFunnelsStore } from '@jsResource/stores/funnels'
import { useStepsStore } from '@jsResource/stores/steps'
import { useBuilderStore } from '@jsResource/stores/builder'
import { storeToRefs } from 'pinia'
import {computed, ref} from 'vue'
import { Link } from '@inertiajs/vue3';
import {getUserFeature} from "../../helper/planHelper.js";
import LoadingDots from "../LoadingDots.vue";
const funnelsStore = useFunnelsStore()
const stepsStore = useStepsStore()
const builderStore = useBuilderStore()
stepsStore.getIndex({funnel : route().params.funnel});
async function createNewStep(isResultStep = false) {
    let step = await stepsStore.createNew({funnel : route().params.funnel, step: { is_result_step : isResultStep } })
    await this.stepsStore.saveShow( { funnel: route().params.funnel, step: stepsStore.show.id } )
    router.visit(route('steps.show', { funnel: route().params.funnel, step: step.id }))
}
const { indexResults, indexNonResults, index, indexLoaded, indexLoading, show, filter } = storeToRefs(stepsStore)
const { editModeSteps } = storeToRefs(builderStore);
const stepDragging = ref(false)
const canCreateNewFunnelStep = computed(() => stepsStore.indexNonResults.length < getUserFeature('funnels.max_steps'))
const canCreateNewResultStep = computed(() => stepsStore.indexResults.length < getUserFeature('funnels.max_result_steps'))
const loading = computed(() => indexLoading.value)
</script>

<script>
import { mapStores } from 'pinia'
import InlineEdit from "../Modules/InlineEdit.vue"
import { router } from '@inertiajs/vue3';
import draggable from "vuedraggable";
export default {
    components: {
        draggable,
        'funnerix-inline-edit' : InlineEdit
    },
    computed: {
        // note we are not passing an array, just one store after the other
        // each store will be accessible as its id + 'Store'
        ...mapStores(useStepsStore, useFunnelsStore, useBuilderStore),
    },
    methods: {
        async navigateTo(step) {
            await this.stepsStore.saveShow( { funnel: route().params.funnel, step: this.stepsStore.show.id } )
            this.resetUi()
            router.visit(this.$route('steps.show', { funnel: route().params.funnel, step: step.id }))
        },
        reorderCb(event) {
            console.log("Reorder");
            if (event.moved) {
                this.stepsStore.reorder({
                    funnel: this.funnelsStore.show.id,
                    step: event.moved.element,
                    from: event.moved.oldIndex,
                    to: event.moved.newIndex,
                    steps: this.stepsStore.indexNonResults
                })
            }
        },
        renameStep(step) {
            console.log(step.slug);
        },
        resetUi() {
            this.builderStore.browserTab = 1
            this.builderStore.editModule = null;
            this.builderStore.showSettingsTab = false;
        },
        rightClickStep(element) {
            this.builderStore.showSettingsTab = true;
            router.get(this.route('steps.show', { funnel: this.route().params.funnel, step: element.id }))
        },
        deleteCb(element) {
            if (confirm('Wirklich unwiderruflich löschen?')) {
                this.stepsStore.deleteShow({
                    funnel: this.funnelsStore.show.id,
                    step: element.id,
                })
                router.get(this.route('funnels.show', { funnel: this.route().params.funnel, step: null }))
            }
        },
        duplicateCb(element) {
            this.stepsStore.duplicate({
                funnel: this.funnelsStore.show.id,
                step: element.id,
            })
        }
    }
}
</script>

<style lang="scss">
.steps-item {
    .dragger-wrapper { opacity:0.2; }
    &:hover .dragger-wrapper { opacity:1; }
}
</style>
