<template>
    <div class="space-y-6 sm:space-y-5">
        <modules-helper-size-switcher v-model="theContent.size" :hide-label="true"></modules-helper-size-switcher>
        <modules-helper-text :multiline="true" v-model="theContent.text"></modules-helper-text>
        <div class="grid grid-cols-2 gap-x-2 gap-y-2" v-if="element.content.styleButton">
            <template v-if="element.content.styleButton">
                <modules-helper-colors v-model="element.content.styleButton.backgroundColor"><template #label>Button Hintegr.</template></modules-helper-colors>
                <modules-helper-colors v-model="element.content.styleButton.color"><template #label>Button Textfarbe</template></modules-helper-colors>
            </template>
            <modules-helper-colors v-model="element.content.style.backgroundColor"><template #label>Modul Hintergr.</template></modules-helper-colors>
        </div>
        <modules-helper-checkbox v-if="element.content.styleButton && !element.content.styleButton.backgroundColor && !element.content.styleButton.color" :propName="'invert'" v-model="theContent.invert"><template #head>Farbenumkehr?</template> Sollen die Designfarben umgekehrt werden?</modules-helper-checkbox>
        <modules-helper-jump v-model="theContent.jumpTo"></modules-helper-jump>
        <modules-helper-checkbox :propName="'validation'" v-model="theContent.validation"><template #head>Prüfen auf valide Formulardaten</template> Sonst ist dieses Element deaktiviert</modules-helper-checkbox>
        <modules-helper-animation v-model="theContent.animate"></modules-helper-animation>
        <modules-helper-checkbox v-if="theContent.animate != null" :propName="'animateOnHover'" v-model="theContent.animateOnHover"><template #head>Nur beim Hover animieren?</template> Geeignet für PC Darstellung</modules-helper-checkbox>
        <div class="grid grid-cols-2 gap-x-2">
            <modules-helper-padding v-model="theContent.paddingTop"><template #label>{{$t('Margin top')}}</template></modules-helper-padding>
            <modules-helper-padding v-model="theContent.paddingBottom"><template #label>{{$t('Margin bottom')}}</template></modules-helper-padding>
        </div>
        <modules-helper-id-name :element="element"></modules-helper-id-name>
    </div>
</template>

<script>
import modulesMixin from "../modulesMixin";
export default {
    mixins: [ modulesMixin ],
    methods: {
        removeItem(index) {
            this.theContent.items.splice(index, 1);
        }
    }
}
</script>

<style>

</style>
