<template>
    <div>
        <div class="relative flex items-start">
            <div class="flex h-5 items-center">
                <input
                :value="modelValue"
                @input="inputCb"
                :id="propName"
                :aria-describedby="propName"
                :name="propName"
                :checked="modelValue"
                :disabled="disabled"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 disabled:ring-gray-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none disabled:cursor-not-allowed" />
            </div>
            <div class="ml-3 text-sm">
                <label :for="propName" class="font-medium text-gray-700"><slot name="head"></slot></label>
                <span :id="propName" class="text-gray-500">
                    <span class="sr-only"><slot name="head"></slot></span>
                    <slot></slot>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    propName: { type: String, required: true, }, // name of the model property
    preview: { type: Boolean, default: false, },
    editable: { type: Boolean, default: true, },
    disabled: { type: Boolean, default: false, },
    modelValue: {
      type: [Boolean, Number],
      default: '',
    },
  },

  methods: {
    inputCb(event) {
        this.$emit('update:modelValue', event.target.checked)
    }
  },

  emits: ['update:modelValue'],
}
</script>

<style>

</style>
