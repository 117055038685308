import { mapStores } from 'pinia'
import { useBuilderStore } from '@jsResource/stores/builder'
import { useFunnelsStore } from '@jsResource/stores/funnels'
import { useStepsStore } from '@jsResource/stores/steps'
export default {
    props: {
        integration: Object,
        model: { type: String, default: 'steps' }
    },
    methods: {
        save() {
            this.theStore.updateIntegration({
                funnel: this.funnelsStore.show.id,
                step: this.stepsStore.show.id,
                integration: this.integration.id,
                data: this.pivot.data
            });
        }
    },
    computed: {
        funnel() {
            return this.funnelsStore.show;
        },
        ...mapStores(useBuilderStore),
        ...mapStores(useFunnelsStore),
        ...mapStores(useStepsStore),
        pivot() {
            return this.integration.pivot;
        },
        theStore() {
            if (this.model == "steps") {
                return this.stepsStore;
            } else {
                return this.funnelsStore;
            }
        },
    }
}
