<template>
    <div class="space-y-6 sm:space-y-5">
        <modules-helper-size-switcher v-model="theContent.headerSize"><template #label>{{$t('Font size')}}</template></modules-helper-size-switcher>
        <modules-helper-text v-model="theContent.preheader"><template #label>{{$t('Preheader')}}</template></modules-helper-text>
        <modules-helper-text v-model="theContent.header"><template #label>{{$t('Title')}}</template></modules-helper-text>
        <modules-helper-text v-model="theContent.subline"><template #label>{{$t('Subline')}}</template></modules-helper-text>

        <div class="grid grid-cols-2 gap-x-2">
            <modules-helper-colors v-model="element.content.style.backgroundColor"><template #label>{{$t('Background Color')}}</template></modules-helper-colors>
            <modules-helper-colors v-model="element.content.style.color"><template #label>{{$t('Color')}}</template></modules-helper-colors>
        </div>
        <div class="grid grid-cols-2 gap-x-2">
            <modules-helper-padding v-model="theContent.paddingTop"><template #label>{{$t('Margin top')}}</template></modules-helper-padding>
            <modules-helper-padding v-model="theContent.paddingBottom"><template #label>{{$t('Margin bottom')}}</template></modules-helper-padding>
        </div>
        <modules-helper-id-name :element="element"></modules-helper-id-name>
    </div>
</template>

<script>
import modulesMixin from "../modulesMixin";
export default {
    mixins: [ modulesMixin ]
}
</script>

<style>

</style>
