<template>
    <div>
        <label for="type" class="block text-sm font-medium text-gray-700">Typ (Darstellung)</label>
        <span class="isolate inline-flex rounded-md">
            <button @click="inputCb('list')"
                type="button" v-if="modes.includes('list')"
                :class="modelValue == 'list' ? 'border-indigo-300 bg-white text-indigo-700 hover:bg-indigo-50' : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'"
                class="relative inline-flex items-center rounded-l-md border px-4 py-2 text-sm font-medium focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            >
                <svg class="h-4 w-4 mr-1" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="view stacked" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                    <g data-v-41be6633=""><path d="M3 0h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3zm0 8h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3z"></path></g>
                </svg>
                Liste
            </button>
            <button @click="inputCb('flex')"
                type="button"  v-if="modes.includes('flex')"
                :class="modelValue == 'flex' ?  'border-indigo-300 bg-white text-indigo-700 hover:bg-indigo-50' : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'"
                class="relative -ml-px inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            >
                <svg class="h-4 w-4 mr-1" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="grid3x2 gap" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                    <g data-v-41be6633=""><path d="M4 4v2H2V4h2zm1 7V9a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm0-5V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm5 5V9a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm0-5V4a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zM9 4v2H7V4h2zm5 0h-2v2h2V4zM4 9v2H2V9h2zm5 0v2H7V9h2zm5 0v2h-2V9h2zm-3-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V4zm1 4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1h-2z"></path></g>
                </svg>
                Flex
            </button>
            <button @click="inputCb('grid')"
                type="button"  v-if="modes.includes('grid')"
                :class="modelValue == 'grid' ? 'border-indigo-300 bg-white text-indigo-700 hover:bg-indigo-50' : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'"
                class="relative -ml-px inline-flex items-center rounded-r-md border px-4 py-2 text-sm font-medium focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            >
                <svg class="h-4 w-4 mr-1" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="grid" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                    <g data-v-41be6633=""><path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"></path></g>
                </svg>
                Grid
            </button>
        </span>
    </div>
</template>

<script>
export default {
  props: {
    preview: { type: Boolean, default: false, },
    editable: { type: Boolean, default: true, },
    modes: { type: Array, default: (['grid','flex','list']), },
    modelValue: {
      type: String,
      default: '',
    },
  },

  methods: {
    inputCb(value) {
        this.$emit('update:modelValue', value)
    }
  },

  emits: ['update:modelValue'],
}
</script>

<style>

</style>
