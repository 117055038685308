<template>
    <div class="space-y-6 sm:space-y-5">
        <modules-helper-text v-model="theContent.header"><template #label>{{$t('Title')}}</template></modules-helper-text>
        <modules-helper-text v-model="theContent.subline"><template #label>{{$t('Subline')}}</template></modules-helper-text>
        <modules-helper-text :multiline="false" v-model="theContent.placeholder"><template #label>{{$t('Placeholder')}}</template></modules-helper-text>
        <div class="grid grid-cols-2 gap-x-2">
            <modules-helper-colors v-model="element.content.style.backgroundColor"><template #label>{{$t('Background Color')}}</template></modules-helper-colors>
            <modules-helper-colors v-model="element.content.style.color"><template #label>{{$t('Color')}}</template></modules-helper-colors>
        </div>
        <modules-helper-id-name :element="element"></modules-helper-id-name>
    </div>
</template>

<script>
import modulesMixin from "../modulesMixin";
export default {
    mixins: [ modulesMixin ],
    methods: {
        removeItem(index) {
            this.theContent.items.splice(index, 1);
        }
    }
}
</script>

<style>

</style>
