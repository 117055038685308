<template>
    <div>
        <draggable v-if="show && show.layout"
            class="dragArea list-group"
            :list="show.layout"
            group="modules"
            @change="log"
            handle=".handle"
            item-key="id"
        >
            <template #item="{ element, index }">
                <div
                class="list-group-item mb-3 border"
                :class="hoveringBuilderIndex == index ? 'border-indigo-500 ring-1 ring-indigo-500' : 'border-transparent'"
                @mouseenter="hovering(index)" @mouseleave="hovering(null)"
                >
                    <div class="bg-white overflow-hidden shadow rounded-lg">
                        <div class="flex justify-between py-2 px-2 border-b border-gray-200 handle">
                            <h5>{{element.type}}</h5>
                            <div class="flex gap-x-1">
                                <!-- <div class="cursor-context-menu">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.098 19.902a3.75 3.75 0 005.304 0l6.401-6.402M6.75 21A3.75 3.75 0 013 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 003.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008z" />
                                    </svg>
                                </div> -->
                                <div class="delete-me cursor-pointer" @click="removeElement(element, index)">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                    </svg>
                                </div>
                                <div class="cursor-move">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="px-4 py-5 mb-12">
                            <component :is="'modules-forms-'+element.type"
                            :disabled="dragging"
                            :preview="true"
                            :element="element"
                            :funnel="funnel"></component>
                        </div>
                    </div>
                </div>
            </template>
        </draggable>
        <div class="mt-4 flex" v-if="!show || !show.layout || show.layout.length == 0">
            <a href="#" class="text-sm font-medium text-indigo-600 hover:text-indigo-500">Drag your first module here<span aria-hidden="true"> &rarr;</span></a>
        </div>
    </div>
</template>

<script setup>
import { useFunnelsStore } from '@jsResource/stores/funnels'
import { useStepsStore } from '@jsResource/stores/steps'
import { useBuilderStore } from '@jsResource/stores/builder'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
const builderStore = useBuilderStore()
const { hoveringBuilderIndex, dragging } = storeToRefs(builderStore)
const stepsStore = useStepsStore()
stepsStore.getIndex({funnel : route().params.funnel});
const funnelsStore = useFunnelsStore()
const funnel = storeToRefs(funnelsStore).show;
function createNewStep() { stepsStore.createNew({funnel : route().params.funnel}) }
const { index, indexLoaded, indexLoading, show, filter } = storeToRefs(stepsStore)
const layout = computed( () => show.layout );
function hovering(index) { builderStore.hoveringIndex = index }
</script>

<script>
import builderMixin from "./builderMixin";
import draggable from "vuedraggable";
export default {
    components: {
        draggable
    },
    mixins: [builderMixin],
    methods: {
        log: function(evt) {
            console.log("Editor");
            console.log(evt);
        },
    }
}
</script>
