<template>
    <button
        @click.prevent.stop="iconbrowserItem = item; showIconbrowserModal = true;"
        :class="{
            'w-full': fullWidth,
            'w-auto': !fullWidth
        }"
        class="icon-browser-button inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    >
        <slot>
            <component v-if="item.iconSet == 'heroicon' && allHeroIcons[item.iconType] && allHeroIcons[item.iconType][item.iconName]"
                class="w-5 h-5" :is="allHeroIcons[item.iconType][item.iconName]"></component>
            <span v-else-if="item.iconSet == 'emoji'">{{item.iconName}}</span>
            <DotsHorizontalIcon v-else class="icon-dots h-5 w-5 text-gray-400" />
        </slot>
    </button>
</template>

<script setup>
defineProps({
    item: Object,
    fullWidth: { type: Boolean, default: false }
});
import { ref, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { DotsHorizontalIcon } from "@heroicons/vue/outline"
import { SelectorIcon, ShieldCheckIcon,EmojiHappyIcon } from '@heroicons/vue/outline'

import * as outlineIcons  from '@heroicons/vue/outline'
import * as solidIcons  from '@heroicons/vue/solid'
const allHeroIcons = { outline: outlineIcons, solid: solidIcons };

import { useBuilderStore } from '@jsResource/stores/builder'
const builderStore = useBuilderStore()
const { showIconbrowserModal, iconbrowserItem, iconbrowserTab } = storeToRefs(builderStore)
</script>
<script>
export default {
    emits: ['selected'],
    methods: {
        selected(iconSet, iconType, iconName, icon = null) {
            this.open = false;
            this.$emit('selected', { iconSet, iconType, iconName, icon });
        },
        selectEmoji(emoji) {
            this.selected('emoji', emoji.id, emoji.native, emoji.unified);
        }
    }
}
</script>
