<script setup>
import JetButton from '@jsResource/Jetstream/Button.vue';
import { Switch } from '@headlessui/vue'
</script>
<template>
    <div>
        <label for="header" class="block text-sm font-medium text-gray-700">{{$t('Group ID after successful submission')}}</label>
        <div class="mt-1 mb-2">
            <input
                type="text"
                name="list_id"
                id="list_id"
                placeholder="123"
                v-model="pivot.data.group_id"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
            >
        </div>
        <label for="header" class="block text-sm font-medium text-gray-700">{{$t('Conditional subscription?')}}</label>
        <div class="mt-1 mb-2">
            <Switch v-model="pivot.data.only_if" :class="[pivot.data.only_if ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                <span class="sr-only">Only If</span>
                <span aria-hidden="true" :class="[pivot.data.only_if ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
            </Switch>
        </div>
        <template v-if="pivot.data.only_if">
            <label for="header" class="block text-sm font-medium text-gray-700">{{$t('Only if Variable...')}}</label>
            <div class="mt-1 mb-2">
                <input
                    type="text"
                    name="only_if_variable"
                    id="only_if_variable"
                    :placeholder="$t('Variable Name')"
                    v-model="pivot.data.only_if_variable"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                >
            </div>
            <label for="header" class="block text-sm font-medium text-gray-700">{{$t('...has Value')}}</label>
            <div class="mt-1 mb-2">
                <input
                    type="text"
                    name="only_if_value"
                    id="only_if_value"
                    :placeholder="$t('Variable Value')"
                    v-model="pivot.data.only_if_value"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                >
            </div>
        </template>
        <!-- <label for="header" class="block text-sm font-medium text-gray-700">{{$t('Form ID after successful submission')}}</label>
        <div class="mt-1 mb-2">
            <input
                type="text"
                name="form_id"
                id="form_id"
                v-model="pivot.data.form_id"
                :placeholder="'123 ('+$t('optional')+')'"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
            >
        </div> -->
        <JetButton class="mr-4" @click="save">
            {{$t('Save')}}
        </JetButton>
    </div>
</template>

<script>
import partialMixin from "./partialMixin"
export default {
    mixins: [partialMixin]
}
</script>

<style>

</style>
