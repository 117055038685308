<script setup>
import { Link } from '@inertiajs/vue3';
const props = defineProps({
    funnel: Object,
    options: Object,
});

import { useFunnelsStore } from '@jsResource/stores/funnels'
const funnelsStore = useFunnelsStore()
const deleteCb = async function() {
  if (confirm("Wirklich alle Statistiken dieses Funnels zurücksetzen?")) {
    await funnelsStore.resetStats({funnel:props.funnel.id, steps: true});
    window.location.reload();
  }
}
</script>

<script>
import ApexCharts from 'apexcharts'
export default {
    mounted() {
        console.log(this.options);
        let chart = new ApexCharts(this.$refs.chart, this.options)
        chart.render()
    }
}
</script>

<template>
    <div>
        <h3 class="text-lg font-medium leading-6 text-gray-900">{{$t('Bounces during the funnel')}}</h3>
        <div class="mt-5 overflow-hidden rounded-lg bg-white shadow mb-5">
            <div class="px-4 py-5 sm:p-6">
                <div id="chart" ref="chart"></div>
            </div>
        </div>

        <div class="overflow-hidden rounded-lg bg-white shadow">
            <div class="px-4 py-5 sm:p-6">
                <p>
                    <button @click.stop="deleteCb" type="button" class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        {{$t('Reset each step stats')}}
                    </button>
                </p>
            </div>
        </div>
    </div>
</template>
