<script setup>
import { Link } from '@inertiajs/vue3';
defineProps({
    small: { type: Boolean, default: false },
    funnel: Object,
    responses: Array,
    steps: {type: Array, required: false, default: ([]) },
    resultStepsCount: {type: Number, required: false, default: 0 },
});
</script>

<template>
    <div class="px-4 sm:px-6 lg:px-8">
        <div class="flex flex-col">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle">
                    <div class="shadow-sm ring-1 ring-black ring-opacity-5">
                        <table class="min-w-full border-separate" style="border-spacing: 0">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                                        {{$t('ID')}}
                                    </th>
                                    <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                                        {{$t('Email')}}
                                    </th>
                                    <th scope="col" class="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                        {{$t('Sent at')}}
                                    </th>
                                    <th v-for="idx in resultStepsCount" :key="idx+'th'" scope="col" class="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                                        {{$t('Step')}} {{idx}}
                                    </th>
                                    <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                                        <span class="sr-only">{{$t('Show')}}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white">
                                <tr v-for="(response, responseIdx) in responses" :key="response.id" :id="`r${response.id}`">
                                    <td :class="[
                                        responseIdx !== responses.length - 1 ? 'border-b border-gray-200' : '',
                                        route().params.response && route().params.response == response.id ? 'bg-gray-100 text-indigo-500' : '',
                                        'whitespace-nowrap px-3 py-4 text-sm text-gray-300'
                                    ]">{{ response.id }}</td>
                                    <td :class="[
                                        responseIdx !== responses.length - 1 ? 'border-b border-gray-200' : '',
                                        route().params.response && route().params.response == response.id ? 'bg-gray-100 text-indigo-500' : '',
                                        'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'
                                    ]"><a :name="`r${response.id}`"></a>{{ response.email ?? $t('none') }}</td>
                                    <td :class="[
                                        responseIdx !== responses.length - 1 ? 'border-b border-gray-200' : '',
                                        route().params.response && route().params.response == response.id ? 'bg-gray-100 text-indigo-500' : '',
                                        'whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                                    ]">{{ $moment(response.created_at).format('lll') }}</td>
                                    <td v-for="ansIdx in resultStepsCount" :key="responseIdx+'.'+ansIdx+'td'" :class="[
                                        responseIdx !== responses.length - 1 ? 'border-b border-gray-200' : '',
                                        route().params.response && route().params.response == response.id ? 'bg-gray-100 text-indigo-500' : '',
                                        'whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                                    ]">
                                        <template v-if="response.formed_responses[ansIdx-1] && response.formed_responses[ansIdx-1].length > 0">
                                            <template v-for="(answ, aidx) in response.formed_responses[ansIdx-1]" :key="aidx+ansIdx+responseIdx">
                                                <ul v-if="answ.type == 'multiple' && typeof answ.answer === 'object'">
                                                    <template v-for="(an, ax) in answ.answer" :key="aidx+ansIdx+responseIdx+ax+'x'">
                                                        <li>{{an}}</li>
                                                        <!-- <div v-for="(value, propertyName) in an" :key="aidx+ansIdx+responseIdx+propertyName"><strong>{{propertyName}}</strong>: {{value}}</div> -->
                                                    </template>
                                                </ul>
                                                <template v-else-if="answ.type === 'upload'">
                                                    <a v-if="answ.answer" :href="answ.answer" target="_blank" class="text-indigo-600">{{$t('Download File')}}</a>
                                                    <span v-else class="text-gray-500">{{$t('No file')}}</span>
                                                </template>
                                                <template v-else-if="typeof answ.answer === 'object'">
                                                    <template v-for="an in answ.answer">
                                                        <div v-for="(value, propertyName) in an" :key="aidx+ansIdx+responseIdx+propertyName"><strong>{{propertyName}}</strong>: {{value}}</div>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <div>{{answ.answer}}</div>
                                                </template>
                                            </template>
                                        </template>
                                        <span v-else class="text-gray-500">n/A</span>
                                    </td>
                                    <td :class="[
                                        responseIdx !== responses.length - 1 ? 'border-b border-gray-200' : '',
                                        route().params.response && route().params.response == response.id ? 'bg-gray-100 text-indigo-500' : '',
                                        'relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8'
                                    ]">
                                        <Link :href="$route('responses.show', {funnel, response})" class="text-indigo-600 hover:text-indigo-900">
                                            <button type="button"
                                                :class="route().params.response && route().params.response == response.id ? 'bg-gray-300 text-gray-800' : 'bg-gray-100 text-gray-800'"
                                                class="inline-flex items-center rounded-full border border-transparent p-1 shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                </svg>
                                            </button>
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        let response = this.route().params.response
        let responseId = "r"+response;
        let element = document.getElementById(responseId)
        if (element) {
            element.scrollIntoView({block: 'center'})
        }
    }
}
</script>
