<script setup>
import { ref } from 'vue';
import { router } from '@inertiajs/vue3';
import { useForm } from '@inertiajs/vue3';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import JetButton from '@jsResource/Jetstream/Button.vue';
import JetFormSection from '@jsResource/Jetstream/FormSection.vue';
import JetInput from '@jsResource/Jetstream/Input.vue';
import JetInputError from '@jsResource/Jetstream/InputError.vue';
import JetLabel from '@jsResource/Jetstream/Label.vue';
import JetActionMessage from '@jsResource/Jetstream/ActionMessage.vue';
import JetSecondaryButton from '@jsResource/Jetstream/SecondaryButton.vue';
import {ExclamationCircleIcon} from "@heroicons/vue/outline";

const props = defineProps({
    funnel: Object,
});

const form = useForm({
    _method: 'PUT',
    name: props.funnel.name,
    description: props.funnel.description,
    slug: props.funnel.slug,
    is_disabled: props.funnel.is_disabled,
    allow_step_deeplink: props.funnel.allow_step_deeplink,
    photo: null,
});

const photoPreview = ref(null);
const photoInput = ref(null);

const updateFunnelInformation = () => {
    if (photoInput.value) {
        form.photo = photoInput.value.files[0];
    }

    form.post(route('funnels.update',{funnel:props.funnel}), {
        errorBag: 'updateFunnelInformation',
        preserveScroll: true,
        onSuccess: () => clearPhotoFileInput(),
    });
};

const selectNewPhoto = () => {
    photoInput.value.click();
};

const updatePhotoPreview = () => {
    const photo = photoInput.value.files[0];

    if (! photo) return;

    const reader = new FileReader();

    reader.onload = (e) => {
        photoPreview.value = e.target.result;
    };

    reader.readAsDataURL(photo);
};

const deletePhoto = () => {
    router.delete(route('current-user-photo.destroy'), {
        preserveScroll: true,
        onSuccess: () => {
            photoPreview.value = null;
            clearPhotoFileInput();
        },
    });
};

const clearPhotoFileInput = () => {
    if (photoInput.value?.value) {
        photoInput.value.value = null;
    }
};
</script>

<template>
    <JetFormSection @submitted="updateFunnelInformation">
        <template #title>
            {{$t('Funnel meta information')}}
        </template>

        <template #description>
            {{$t('Update the meta information of this funnel')}}
            <span class="mt-2 inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20" v-if="form.is_disabled">
                <ExclamationCircleIcon class="text-yellow-500 size-5 mr-1" :title="$t('Disabled')" />
                {{$t('Disabled')}}
            </span>
        </template>

        <template #form>
            <!-- Profile Photo -->
            <div class="col-span-6 sm:col-span-4">
                <!-- Profile Photo File Input -->
                <input
                    ref="photoInput"
                    type="file"
                    class="hidden"
                    @change="updatePhotoPreview"
                >

                <JetLabel for="photo" value="Photo" />

                <!-- Current Profile Photo -->
                <div v-show="! photoPreview && funnel.avatar_image_url" class="mt-2">x
                    <img :src="funnel.avatar_image_url" :alt="funnel.name" class="rounded-full h-20 w-20 object-cover">
                </div>

                <!-- New Profile Photo Preview -->
                <div v-show="photoPreview" class="mt-2">
                    <span
                        class="block rounded-full w-20 h-20 bg-cover bg-no-repeat bg-center"
                        :style="'background-image: url(\'' + photoPreview + '\');'"
                    />
                </div>

                <JetSecondaryButton class="mt-2 mr-2" type="button" @click.prevent="selectNewPhoto">
                    {{$t('Select A New Photo')}}
                </JetSecondaryButton>

                <JetSecondaryButton
                    v-if="funnel.profile_photo_path"
                    type="button"
                    class="mt-2"
                    @click.prevent="deletePhoto"
                >
                    {{ $t('Remove Photo') }}
                </JetSecondaryButton>

                <JetInputError :message="form.errors.photo" class="mt-2" />
            </div>

            <!-- Name -->
            <div class="col-span-6 sm:col-span-4">
                <JetLabel for="name" :value="$t('Name')" />
                <JetInput
                    id="name"
                    v-model="form.name"
                    type="text"
                    class="mt-1 block w-full"
                    autocomplete="name"
                />
                <JetInputError :message="form.errors.name" class="mt-2" />
            </div>

            <!-- Description -->
            <div class="col-span-6 sm:col-span-4">
                <JetLabel for="description" :value="$t('Description')" />
                <JetInput
                    id="description"
                    v-model="form.description"
                    type="text"
                    class="mt-1 block w-full"
                />
                <JetInputError :message="form.errors.description" class="mt-2" />
            </div>

            <!-- allow_step_deeplink -->
            <div class="col-span-6 sm:col-span-4">
                <SwitchGroup as="div" class="flex items-center">
                    <Switch v-model="form.allow_step_deeplink" :class="[form.allow_step_deeplink ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']">
                        <span aria-hidden="true" :class="[form.allow_step_deeplink ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                    </Switch>
                    <SwitchLabel as="span" class="ml-3">
                        <span class="text-sm font-medium text-gray-900">{{$t('Allow step deeplink')}} <em>({{ $t('Jumping to a specific step when #hash changes') }})</em></span>
                    </SwitchLabel>
                </SwitchGroup>
            </div>

            <!-- is_disabled -->
            <div class="col-span-6 sm:col-span-4">
                <SwitchGroup as="div" class="flex items-center">
                    <Switch v-model="form.is_disabled" :class="[form.is_disabled ? 'bg-pink-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']">
                        <span aria-hidden="true" :class="[form.is_disabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                    </Switch>
                    <SwitchLabel as="span" class="ml-3">
                        <span class="text-sm font-medium text-gray-900">{{$t('Disable funnel?')}} <em>({{ $t('This shows a disabled message and its not possible to view the funnel') }})</em></span>
                    </SwitchLabel>
                </SwitchGroup>
            </div>
        </template>

        <template #actions>
            <JetActionMessage :on="form.recentlySuccessful" class="mr-3">
                {{$t('Saved')}}.
            </JetActionMessage>

            <JetButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                {{$t('Save')}}
            </JetButton>
        </template>
    </JetFormSection>
</template>
