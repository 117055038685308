<script setup>
import { ref } from 'vue';
import { router } from '@inertiajs/vue3';
import { useForm } from '@inertiajs/vue3';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import JetButton from '@jsResource/Jetstream/Button.vue';
import JetFormSection from '@jsResource/Jetstream/FormSection.vue';
import JetInput from '@jsResource/Jetstream/Input.vue';
import JetInputError from '@jsResource/Jetstream/InputError.vue';
import JetLabel from '@jsResource/Jetstream/Label.vue';
import JetActionMessage from '@jsResource/Jetstream/ActionMessage.vue';
import JetActionSection from '@jsResource/Jetstream/ActionSection.vue';
import JetDialogModal from '@jsResource/Jetstream/DialogModal.vue';
import JetDangerButton from '@jsResource/Jetstream/DangerButton.vue';
import JetSecondaryButton from '@jsResource/Jetstream/SecondaryButton.vue';

const props = defineProps({
    funnel: Object,
});

const confirmingFunnelReplication = ref(false);

const form = useForm({
    _method: 'POST',
});

const confirmFunnelReplication = () => {
    confirmingFunnelReplication.value = true;
};

const replicateFunnel = () => {
    form.post(route('funnels.replicate', {funnel: props.funnel}), {
        preserveScroll: true,
        onSuccess: () => closeModal(),
        onError: (e) => { console.log(e) },
        onFinish: () => form.reset(),
    });
};

const closeModal = () => {
    confirmingFunnelReplication.value = false;
    form.reset();
};
</script>

<template>
    <JetActionSection>
        <template #title>
            {{$t('Replicate Funnel')}}
        </template>

        <template #description>
            {{$t('Create a copy from this funnel and it\'s steps (without data).')}}
        </template>

        <template #content>

            <div class="max-w-xl text-sm text-gray-600">
                {{$t('Create an exact copy of this funnel and it\'s steps. Views stats, answers etc. will not be copied.')}}
            </div>

            <div class="mt-5">
                <JetSecondaryButton @click="confirmFunnelReplication">
                    {{$t('Replicate Funnel')}}
                </JetSecondaryButton>
            </div>

            <!-- Delete Account Confirmation Modal -->
            <JetDialogModal :show="confirmingFunnelReplication" @close="closeModal">
                <template #title>
                    {{ $t('Replicate Funnel') }}
                </template>

                <template #content>
                    {{ $t('Are you sure you want to replicate this funnel? Create an exact copy of this funnel and it\'s steps. Views stats, answers etc. will not be copied.') }}
                </template>

                <template #footer>
                    <JetSecondaryButton @click="closeModal">
                        {{$t('Cancel')}}
                    </JetSecondaryButton>

                    <JetButton
                        class="ml-3"
                        @click="replicateFunnel"
                    >
                        {{$t('Replicate Funnel')}}
                    </JetButton>
                </template>
            </JetDialogModal>
        </template>
    </JetActionSection>
</template>
