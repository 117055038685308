<template>
    <div class="flex flex-wrap">
        <div class="w-full text-center">
            <div ref="btnRef" @mouseenter="toggleTooltip(true)" @mouseleave="toggleTooltip(false)" class="">
                <slot :tooltipShow="tooltipShow" :toggleTooltip="toggleTooltip"></slot>
            </div>
            <div ref="tooltipRef" :class="[{
                    'hidden': !tooltipShow,
                    'block': tooltipShow,
                }, classes]"
                class="border-0 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg">
                <div>
                    <div :class="classes"
                    class="text-white opacity-75 font-semibold p-3 border-b border-solid border-blueGray-100 uppercase rounded-t-lg" v-if="$slots.title || props.title">
                        <slot name="title">{{props.title}}</slot>
                    </div>
                    <div class="text-white p-3" v-if="$slots.content || props.content">
                        <slot name="content">{{props.content}}</slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>

<script setup>
import { createPopper } from "@popperjs/core";
import { compile, computed, ref } from 'vue'
// refs to btnRef
const btnRef = ref(null)
// refs to tooltipRef
const tooltipRef = ref(null)
const props = defineProps({
    position: {
        type: String,
        default: "right",
        validator: (value) => {
            return ["top", "right", "bottom", "left"].includes(value);
        }
    },
    variant: {
        type: String,
        default: "gray"
    },
    bg: {
        type: String,
        default: null,
    },
    title: {
        type: String,
        default: null,
    },
    content: {
        type: String,
        default: null,
    },
    offset: {
        type: Number,
        default: 0,
    },
})
const classes = computed(() => {
    if (props.bg) {
        return props.bg
    }
    return `bg-${props.variant}-600`
})
const tooltipShow = ref(false)
const createPopperInstance = () => {
    createPopper(btnRef.value, tooltipRef.value, {
        placement: props.position,
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [0, props.offset],
                },
            },
        ],
    });
}
const toggleTooltip = (state = null) => {
    if(state !== null){
        tooltipShow.value = state;
        if (state) {
            createPopperInstance();
        }
    } else {
        if(tooltipShow.value){
            tooltipShow.value = false;
        } else {
            tooltipShow.value = true;
            createPopperInstance();
        }
    }
}
</script>
