<script setup>
import { ref } from 'vue';
import { router } from '@inertiajs/vue3';
import { useForm } from '@inertiajs/vue3';
import JetButton from '@jsResource/Jetstream/Button.vue';
import JetFormSection from '@jsResource/Jetstream/FormSection.vue';
import JetInput from '@jsResource/Jetstream/Input.vue';
import JetInputError from '@jsResource/Jetstream/InputError.vue';
import JetLabel from '@jsResource/Jetstream/Label.vue';
import JetActionMessage from '@jsResource/Jetstream/ActionMessage.vue';
import JetSecondaryButton from '@jsResource/Jetstream/SecondaryButton.vue';
import FunnerixBuilderIntegrationsbrowser from '@jsResource/Funnerix/Builder/Integrationsbrowser.vue';

const props = defineProps({
    funnel: Object,
    availableIntegrations: { type: Array, default: () => ([]) },
    installedIntegrations: { type: Array, default: () => ([]) },
});

const form = useForm({
    _method: 'PUT',
    name: props.funnel.name,
    description: props.funnel.description,
    slug: props.funnel.slug,
    photo: null,
});

const photoPreview = ref(null);
const photoInput = ref(null);

const updateFunnelInformation = () => {
    if (photoInput.value) {
        form.photo = photoInput.value.files[0];
    }

    form.post(route('funnels.update',{funnel:props.funnel}), {
        errorBag: 'updateFunnelInformation',
        preserveScroll: true,
        onSuccess: () => clearPhotoFileInput(),
    });
};

const selectNewPhoto = () => {
    photoInput.value.click();
};

const updatePhotoPreview = () => {
    const photo = photoInput.value.files[0];

    if (! photo) return;

    const reader = new FileReader();

    reader.onload = (e) => {
        photoPreview.value = e.target.result;
    };

    reader.readAsDataURL(photo);
};

const deletePhoto = () => {
    router.delete(route('current-user-photo.destroy'), {
        preserveScroll: true,
        onSuccess: () => {
            photoPreview.value = null;
            clearPhotoFileInput();
        },
    });
};

const clearPhotoFileInput = () => {
    if (photoInput.value?.value) {
        photoInput.value.value = null;
    }
};
</script>

<template>
    <JetFormSection @submitted="updateFunnelInformation">
        <template #title>
            {{$t('Funnel integrations')}}
        </template>

        <template #description>
            {{$t('Set up the integrations used by this funnel')}}
        </template>

        <template #form>
            <div class="col-span-6">
                <h5 class="mb-2">{{$t('Available integrations')}}</h5>

                <FunnerixBuilderIntegrationsbrowser key="FunnerixIntegrationsbrowser"
                    v-if="availableIntegrations && installedIntegrations"
                    :available-integrations="availableIntegrations"
                    :installed-integrations="installedIntegrations"
                    :model="'funnels'" />
            </div>
        </template>

        <template #actions>
            <JetActionMessage :on="form.recentlySuccessful" class="mr-3">
                {{$t('Saved')}}.
            </JetActionMessage>

            <JetButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                {{$t('Save')}}
            </JetButton>
        </template>
    </JetFormSection>
</template>
