import './bootstrap.js'
import '@cssResource/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3'

import { i18nVue } from 'laravel-vue-i18n'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import Notifications from 'notiwind'

import moment from "moment"
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import {getUserFeature} from "./helper/planHelper.js";


const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Funnerix';

const app = createInertiaApp({
    progress: {
        color: '#4B5563',
    },
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    async setup({ el, App, props, plugin }) {
        const vueApp = createApp({ render: () => h(App, props) });

        // pinia
        const pinia = createPinia()
        vueApp.use(pinia.use(piniaPluginPersistedstate))

        // defaults
        vueApp.use(plugin)
        vueApp.mixin({
            methods: {
                route,
                getUserFeature(featureString, planIndex = false) {
                    return getUserFeature(featureString, planIndex)
                }
            },
            computed: {
                userPlanIndex() {
                    return window.$funnerix && typeof window.$funnerix.planindex !== "undefined" ? window.$funnerix.planindex : false
                },
                funnerixPlans() {
                    return window.$funnerix && window.$funnerix.plans ? window.$funnerix.plans : {}
                },
                funnerixObject() {
                    return window.$funnerix ? window.$funnerix : {}
                },
                funnerixVersion() {
                    return window.$funnerix && window.$funnerix.version ? window.$funnerix.version : 'n/A'
                },
                funnerixLang() {
                    return window.$funnerix && window.$funnerix.lang ? window.$funnerix.lang : 'de'
                }
            }
        })

        vueApp.use(Notifications)

        /**------------- Load all funnerix components ------------**/
        const req = import.meta.glob('./Funnerix/**/*.vue', {
            eager: true
        });
        for (const [key, module] of Object.entries(req)) {
            // Extract the component name from the file path, replacing non-alphanumeric characters with dashes and converting to lowercase
            const name = key.match(/\.\/Funnerix\/(.*)\.vue$/)[1].replace(/[\/_]/g, "-").toLowerCase();

            // Register the component with the Vue application
            vueApp.component(name, module.default);
        }


        //vueApp.component('modules-helper-text', () => import('./Funnerix/Modules/HelperText.vue'))

        // i18n Multilang Vue 3
        vueApp.use(i18nVue, {
            resolve: async lang => {
                const langs = import.meta.glob('../../lang/*.json');
                return await langs[`../../lang/${lang}.json`]();
            }
        });

        vueApp.config.globalProperties.$route = route
        moment.locale('de')
        vueApp.config.globalProperties.$moment = moment

        vueApp.mount(el);

        return vueApp;
    },
});
