<template>
    <div class="space-y-6 sm:space-y-5">
        <modules-helper-size-switcher v-model="theContent.headerSize" :hide-label="false"><template #label>{{$t('Header fontsize')}}</template></modules-helper-size-switcher>
        <modules-helper-type-list-grid-flex-switcher v-model="theContent.type"></modules-helper-type-list-grid-flex-switcher>
        <div v-if="theContent.type != 'list'">
            <label for="iconPlace" class="block text-sm font-medium text-gray-700">Bild / Icon platziert</label>
            <select v-model="theContent.iconPlace" id="iconPlace" name="iconPlace" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                <option value="top">Oben</option>
                <option value="bottom">Unten</option>
            </select>
        </div>

        <modules-helper-required v-model="theContent.required"></modules-helper-required>
        <modules-helper-invert v-model="theContent.invert"></modules-helper-invert>
        <modules-helper-link-mode v-model="theContent.linkMode"></modules-helper-link-mode>

        <div class="grid grid-cols-2 gap-x-2">
            <modules-helper-colors v-model="element.content.style.backgroundColor"><template #label>{{$t('Background Color')}}</template></modules-helper-colors>
            <modules-helper-colors v-model="element.content.style.color"><template #label>{{$t('Color')}}</template></modules-helper-colors>
        </div>
        <div class="grid grid-cols-2 gap-x-2">
            <modules-helper-padding v-model="theContent.paddingTop"><template #label>{{$t('Margin top')}}</template></modules-helper-padding>
            <modules-helper-padding v-model="theContent.paddingBottom"><template #label>{{$t('Margin bottom')}}</template></modules-helper-padding>
        </div>

        <h5 class="block text-sm font-medium text-gray-700">{{$t('List elements')}}</h5>
        <div class="divide-y divide-gray-200 pt-0">
            <div v-for="item, index in theContent.items" :key="index" class="pb-4">
                <div>
                    <div class="flex justify-end">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="cursor-pointer text-gray-600 w-4 h-4" @click="removeItem(index)">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>
                    </div>

                    <div class="mt-1 flex gap-x-1">
                        <div>
                            <icon-browser-button @selected="selectedCb(theContent.items[index], $event)" :item="item" />
                        </div>
                        <div class="flex-auto">
                            <input
                                v-model="theContent.items[index].title"
                                type="text"
                                name="subline"
                                id="subline"
                                placeholder="Your text goes here"
                                class="w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>

                        <div>
                            <modules-helper-colors v-model="theContent.items[index].style.backgroundColor" :hide-input="true" :hide-label="true"><template #label>{{$t('Background Color')}}</template></modules-helper-colors>
                        </div>
                    </div>
                </div>
                <modules-helper-jump :hide-label="true" v-if="!theContent.multiple" v-model="theContent.items[index].jumpTo"></modules-helper-jump>
            </div>
        </div>
        <modules-helper-jump :hide-label="true" v-if="!theContent.multiple" v-model="theContent.jumpTo"></modules-helper-jump>
        <modules-helper-id-name :element="element"></modules-helper-id-name>
    </div>
</template>

<script>
import IconBrowserButton from "../shared/IconBrowserButton.vue"
import modulesMixin from "../modulesMixin";
export default {
    mixins: [ modulesMixin ],
    components: {
        IconBrowserButton,
    },
    methods: {
        selectedCb(item, event) {
            item.iconSet = event.iconSet
            item.iconType = event.iconType
            item.iconName = event.iconName
        },
        removeItem(index) {
            this.theContent.items.splice(index, 1);
        }
    }
}
</script>

<style>

</style>
