<template>
    <div class="space-y-6 sm:space-y-5">
        <modules-helper-size-switcher v-model="theContent.size" :hide-label="true"></modules-helper-size-switcher>
        <div class="grid grid-cols-3 gap-x-3">
            <div>
                <label for="min" class="block text-sm font-medium text-gray-700">Minimal</label>
                <div class="mt-1">
                    <input
                        v-model="theContent.min"
                        type="number"
                        name="min"
                        min="0"
                        id="min"
                        placeholder="Minimal"
                        class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                    >
                </div>
            </div>
            <div>
                <label for="max" class="block text-sm font-medium text-gray-700">Maximal</label>
                <div class="mt-1">
                    <input
                        v-model="theContent.max"
                        :min="theContent.min"
                        type="number"
                        name="max"
                        id="max"
                        placeholder="Maximal"
                        class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                    >
                </div>
            </div>
            <div>
                <label for="start" class="block text-sm font-medium text-gray-700">Startwert</label>
                <div class="mt-1">
                    <input
                        v-model="theContent.start"
                        type="number"
                        name="start"
                        min="0"
                        :max="theContent.max"
                        id="start"
                        placeholder="Startwert"
                        class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                    >
                </div>
            </div>
        </div>
        <modules-helper-link-mode v-model="theContent.linkMode"></modules-helper-link-mode>
        <modules-helper-jump v-model="theContent.jumpTo"></modules-helper-jump>
        <div class="grid grid-cols-2 gap-x-2">
            <template v-if="element.content.styleStar && element.content.styleStarSelected">
                <modules-helper-colors v-model="element.content.styleStar.color"><template #label>{{$t('Icon')}}</template></modules-helper-colors>
                <modules-helper-colors v-model="element.content.styleStarSelected.color"><template #label>{{$t('Selected Icon')}}</template></modules-helper-colors>
            </template>
            <modules-helper-colors v-model="element.content.style.backgroundColor"><template #label>{{$t('Background Color')}}</template></modules-helper-colors>
        </div>
        <div class="grid grid-cols-2 gap-x-2">
            <modules-helper-padding v-model="theContent.paddingTop"><template #label>{{$t('Margin top')}}</template></modules-helper-padding>
            <modules-helper-padding v-model="theContent.paddingBottom"><template #label>{{$t('Margin bottom')}}</template></modules-helper-padding>
        </div>
        <modules-helper-id-name :element="element"></modules-helper-id-name>
    </div>
</template>

<script>
import modulesMixin from "../modulesMixin";
export default {
    mixins: [ modulesMixin ],
    methods: {
        removeItem(index) {
            this.theContent.items.splice(index, 1);
        }
    }
}
</script>

<style>

</style>
