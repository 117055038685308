<template>
    <div class="mt-2">
        <div class="space-y-6 sm:space-y-5" v-if="show && show.id">
            <div>
                <h5>Einstellungen dieser Seite</h5>
            </div>
            <div>
                <label for="title" class="block text-sm font-medium text-gray-700">Seitentitel (optional)</label>
                <div class="mt-1">
                    <input
                    v-model="show.title"
                    type="text"
                    name="title"
                    id="title"
                    placeholder="Your text goes here"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                    >
                </div>
            </div>
            <div>
                <label for="slug" class="block text-sm font-medium text-gray-700">Pfad (Slug)</label>
                <div class="mt-1">
                    <input
                    v-model="show.slug"
                    type="text"
                    name="slug"
                    id="slug"
                    placeholder="Your text goes here"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                    >
                </div>
            </div>
            <SwitchGroup as="div" class="flex items-center">
                <Switch v-model="show.is_result_step" :class="[show.is_result_step ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']">
                    <span aria-hidden="true" :class="[show.is_result_step ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                </Switch>
                <SwitchLabel as="span" class="ml-3">
                    <span class="text-sm font-medium text-gray-900">Ist eine Ergebnisseite</span>
                </SwitchLabel>
            </SwitchGroup>
            <div>
                <h5>Tracking Informationen</h5>
            </div>
            <div>
                <label for="tracking_head" class="block text-sm font-medium text-gray-700">
                    Trackingcode (head)
                    <UpgradeBadge :showMe="!getUserFeature('features.tracking_codes')" />
                </label>
                <div class="mt-1">
                    <textarea
                    v-model="show.tracking_head"
                    placeholder="Wird dynamisch geladen"
                    rows="5"
                    name="tracking_head"
                    id="tracking_head"
                    :disabled="!getUserFeature('features.tracking_codes')"
                    class="shadow-sm font-mono focus:ring-indigo-500 focus:border-indigo-500 block w-full text-xs border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"></textarea>
                </div>
            </div>
            <div>
                <label for="tracking_body" class="block text-sm font-medium text-gray-700">
                    Trackingcode (body)
                    <UpgradeBadge :showMe="!getUserFeature('features.tracking_codes')" />
                </label>
                <div class="mt-1">
                    <textarea
                    v-model="show.tracking_body"
                    placeholder="Wird dynamisch geladen"
                    rows="5"
                    name="tracking_body"
                    id="tracking_body"
                    :disabled="!getUserFeature('features.tracking_codes')"
                    class="shadow-sm font-mono focus:ring-indigo-500 focus:border-indigo-500 block w-full text-xs border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"></textarea>
                </div>
            </div>

            <div v-if="getUserFeature('features.import_export_layouts')">
                <h5>Expertenmodus</h5>
                <p class="text-xs mb-2">Externe Layouts importieren</p>
                <span class="isolate inline-flex rounded-md">
                    <a @click="exportMe = !exportMe" class="cursor-pointer relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                    :class="exportMe ? 'bg-indigo-100 text-indigo-500 border-indigo-500' : ''">
                        Layout exportieren
                    </a>
                    <a @click="importLayout" class="cursor-pointer relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                        Layout importieren
                    </a>
                </span>
            </div>

            <div v-if="exportMe">
                <label for="layout" class="block text-sm font-medium text-gray-700">Layout String (JSON)</label>
                <div class="mt-1">
                    <textarea
                    :value="layoutStr"
                    placeholder="Wird dynamisch geladen"
                    rows="5"
                    name="layout"
                    id="layout"
                    readonly
                    class="shadow-sm font-mono focus:ring-indigo-500 focus:border-indigo-500 block w-full text-xs border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 read-only:bg-slate-50 read-only:cursor-not-allowed read-only:text-slate-500 read-only:border-slate-200 read-only:shadow-none"></textarea>
                </div>
            </div>

            <div>
                <button
                @click="$emit('save')"
                type="button"
                class="inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-sm font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    Speichern und schließen...
                </button>
            </div>

            <div>
                <button
                @click="deleteCb"
                type="button"
                class="inline-flex items-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2">
                    Seite löschen...
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import { DotsHorizontalIcon } from "@heroicons/vue/outline"
import { SelectorIcon } from '@heroicons/vue/outline'
import { storeToRefs } from 'pinia'


import * as outlineIcons  from '@heroicons/vue/outline'
import * as solidIcons  from '@heroicons/vue/solid'
const allIcons = { outline: outlineIcons, solid: solidIcons };

import { useStepsStore } from '@jsResource/stores/steps'
import UpgradeBadge from "../Base/UpgradeBadge.vue";
import {getUserFeature} from "../../helper/planHelper.js";
const stepsStore = useStepsStore()
const { show } = storeToRefs(stepsStore)

const exportMe = ref(false)
const layoutStr = computed( () => JSON.stringify(show.value.layout) )
</script>

<script>
import { mapStores } from 'pinia'
export default {
    emits: [ 'delete', 'save' ],
    computed: {
        ...mapStores(useStepsStore),
    },
    methods: {
        deleteCb() {
            if (confirm('Wirklich unwiderruflich löschen?')) {
                this.$emit('delete')
            }
        },
        importLayout() {
            let str = prompt("Layout String einfügen", '')
            if (str && this.stepsStore.show.layout) {
                console.log("Importing ",str);
                try {
                    let layout = JSON.parse(str)
                    this.stepsStore.show.layout = layout
                    this.$notify({
                        group: "builder",
                        title: "Erfolgreich",
                        text: "Das Layout wurde importiert."
                    }, 3000) // 2s
                } catch(e) {
                    console.error(e);
                    alert("Das hat nicht geklappt.");
                }
            } else {
                console.log("Nope");
            }
        }
    }
}
</script>

<style>

</style>
