<script setup>

import {computed} from "vue";

const props = defineProps({
    size: {
        type: Number,
        default: 4
    },
    gap: {
        type: Number,
        default: 2
    },
    dotClass: {
        type: String,
        default: 'bg-black'
    },
});

const dotClasses = computed(() => {
    return [
        props.size ? `w-${props.size}` : 'w-4',
        props.size ? `h-${props.size}` : 'h-4',
        props.dotClass,
    ]
});
</script>

<template>
    <div class='flex justify-center items-center' :class="`space-x-${props.gap}`">
        <span class='sr-only'>Loading...</span>
        <div :class="dotClasses" class='rounded-full animate-bounce [animation-delay:-0.3s]'></div>
        <div :class="dotClasses" class='rounded-full animate-bounce [animation-delay:-0.15s]'></div>
        <div :class="dotClasses" class='rounded-full animate-bounce'></div>
    </div>
</template>
