<template>
    <div class="space-y-4 pl-4 pr-4" v-if="show.settings">
        <div class="mt-4 space-y-3">
            <h5 class="border-b text-gray-600 border-gray-100 text-sm">{{$t('Design settings')}}</h5>
            <Listbox as="div" v-if="show.settings.primary" v-model="show.settings.primary">
                <ListboxLabel class="block text-sm font-medium text-gray-700">
                    <span :class="[ `bg-${show.settings.primary}-500`, 'inline-block h-2 w-2 flex-shrink-0 rounded-full mr-2']" aria-hidden="true" />
                    {{ $t('Primary color') }}
                </ListboxLabel>
                <div class="relative mt-1">
                <ListboxButton class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                    <span class="block truncate capitalize">{{ show.settings.primary }}</span>
                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <ListboxOption as="template" v-for="color in colors" :key="color.id" :value="color.value" v-slot="{ active, selected }">
                            <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                <div class="flex items-center">
                                    <span :class="[ `bg-${color.value}-500`, 'inline-block h-2 w-2 flex-shrink-0 rounded-full']" aria-hidden="true" />
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']" class="capitalize">{{ color.name }}</span>

                                    <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                    </span>
                                </div>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
                </div>
            </Listbox>
        </div>
        <modules-helper-text :multiline="false" :placeholder="$t('HEX Code')" v-model="show.settings.color_bg_custom1" type="color" v-if="show.settings.primary == 'custom1'" name="color_bg_custom1"><template #label>{{ $t('Custom Background Color') }}</template></modules-helper-text>
        <modules-helper-text :multiline="false" :placeholder="$t('HEX Code')" v-model="show.settings.color_text_custom1" type="color" v-if="show.settings.primary == 'custom1'" name="color_text_custom1"><template #label>{{ $t('Custom Accent Text Color') }}</template></modules-helper-text>
        <modules-helper-alignment v-if="show.settings.logoAlign" v-model="show.settings.logoAlign"><template #label>{{$t('Alignment')}} {{$t('Logo')}}</template></modules-helper-alignment>
        <template v-if="show.head_logo_url">
            <modules-helper-size-switcher v-if="show.settings.logoSize" v-model="show.settings.logoSize"></modules-helper-size-switcher>
            <div class="w-full ml-4 mr-4">
                <img :src="show.head_logo_url" class="h-14 max-w-sm" :title="$t('Head Logo')" />
            </div>
        </template>
        <builder-helper-uploader v-if="show && show.id" title="head_logo"
                                 :api-media-url="'/api/funnels/'+show.id+'/image?type=head-logo'"
                                 v-model="show.head_logo_url">
            <template #fileTitle>
                Titelbild hochladen
            </template>
        </builder-helper-uploader>
        <builder-helper-uploader v-if="show && show.id" title="meta_image"
                                 :api-media-url="'/api/funnels/'+show.id+'/image?type=meta-image'"
                                 v-model="show.meta_image_url">
            <template #fileTitle>
                Metabild hochladen
            </template>
        </builder-helper-uploader>
        <template v-if="show.meta_image_url">
            <div class="w-full ml-4 mr-4">
                <img :src="show.meta_image_url" class="h-14 max-w-sm" :title="$t('Meta Image')" />
            </div>
        </template>
        <div class="mt-4 space-y-3">
            <h5 class="border-b text-gray-600 border-gray-100 text-sm">{{$t('Font settings')}}</h5>
            <Listbox as="div" v-if="show.settings.fontFamily" v-model="show.settings.fontFamily">
                <ListboxLabel class="block text-sm font-medium text-gray-700">
                    {{ $t('Font Family') }}
                </ListboxLabel>
                <div class="relative mt-1">
                    <ListboxButton class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                        <span class="block truncate capitalize">{{ $t(show.settings.fontFamily) }}</span>
                        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                    </ListboxButton>

                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                        <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            <ListboxOption as="template" v-for="fontFamily in fontFamilies" :key="fontFamily.id" :value="fontFamily.value" v-slot="{ active, selected }">
                                <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                    <div class="flex items-center">
                                        <span :class="['inline-block h-2 w-2 flex-shrink-0 rounded-full']" aria-hidden="true" />
                                        <span :style="{ fontFamily: fontFamily.value }" :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']" class="capitalize">{{ fontFamily.name }}</span>

                                        <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                    </span>
                                    </div>
                                </li>
                            </ListboxOption>
                        </ListboxOptions>
                    </transition>
                </div>
            </Listbox>
        </div>
        <div class="mt-4 space-y-3">
            <h5 class="border-b text-gray-600 border-gray-100 text-sm">{{$t('Link settings')}}</h5>
            <modules-helper-checkbox propName="'showAffiliate'" v-model="show.settings.showAffiliate" :disabled="!getUserFeature('features.hide_branding')">
                <template #head>Affiliate</template> Affiliate Badge unten anzeigen? &nbsp;
                <div class="float-right"><a href="https://help.funnerix.com/kb/affiliate-partner-programm/" target="_blank" class="text-indigo-500">Hilfe</a></div>
            </modules-helper-checkbox>
            <modules-helper-text v-if="show.settings.showAffiliate" :multiline="false" :placeholder="$t('Your Digistore24 ID')" v-model="show.settings.ds24Aid"><template #label>Digistore24 Affiliate ID</template></modules-helper-text>
            <div class="rounded-md bg-blue-50 p-4" v-if="!getUserFeature('features.hide_branding')">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <svg class="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <a :href="$route('pages.pricing')" target="_blank" class="ml-3 flex-1 md:flex md:justify-between">
                        <p class="text-sm text-blue-700">{{$t('Hide funnerix branding in a higher plan')}}.</p>
                    </a>
                </div>
            </div>

            <modules-helper-text :multiline="false" :placeholder="$t('Imprint Link')" v-model="show.link_imprint"><template #label>Link zur Impressum Seite</template></modules-helper-text>
            <modules-helper-text :multiline="false" :placeholder="$t('Privacy Link')" v-model="show.link_privacy"><template #label>Link zur Datenschutzseite</template></modules-helper-text>
        </div>
    </div>
</template>

<script setup>
import { useFunnelsStore } from '@jsResource/stores/funnels'
import { useBuilderStore } from '@jsResource/stores/builder'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
const funnelsStore = useFunnelsStore()
const builderStore = useBuilderStore()
const { show } = storeToRefs(funnelsStore)

import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/solid'
import {getUserFeature} from "../../helper/planHelper.js";
const colors = [
  { id: 1, value: 'slate', name: 'slate' },
  { id: 2, value: 'gray', name: 'gray' },
  { id: 3, value: 'zinc', name: 'zinc' },
  { id: 4, value: 'neutral', name: 'neutral' },
  { id: 5, value: 'stone', name: 'stone' },
  { id: 6, value: 'red', name: 'red' },
  { id: 7, value: 'orange', name: 'orange' },
  { id: 8, value: 'amber', name: 'amber' },
  { id: 9, value: 'yellow', name: 'yellow' },
  { id: 10, value: 'lime', name: 'lime' },
  { id: 11, value: 'green', name: 'green' },
  { id: 12, value: 'emerald', name: 'emerald' },
  { id: 13, value: 'teal', name: 'teal' },
  { id: 14, value: 'cyan', name: 'cyan' },
  { id: 15, value: 'sky', name: 'sky' },
  { id: 16, value: 'blue', name: 'blue' },
  { id: 17, value: 'indigo', name: 'indigo' },
  { id: 18, value: 'violet', name: 'violet' },
  { id: 19, value: 'purple', name: 'purple' },
  { id: 20, value: 'fuchsia', name: 'fuchsia' },
  { id: 21, value: 'pink', name: 'pink' },
  { id: 22, value: 'rose', name: 'rose' },
  { id: 23, value: 'custom1', name: 'custom 1' },
]
const selected = ref(colors[16])

const fontFamilies = [
    { id: 1, value: 'Open Sans', name: 'Open Sans' },
    { id: 2, value: 'Times New Roman', name: 'Times New Roman' },
    { id: 3, value: 'Arial', name: 'Arial' },
    { id: 4, value: 'Helvetica', name: 'Helvetica' },
];
const selectedFontFamily = ref(fontFamilies[1]);

</script>

