import { defineStore } from 'pinia'
import axios from "axios";
import api from "../helper/api.js";

// funnels is the name of the store. It is unique across your application
// and will appear in devtools
export const useFunnelsStore = defineStore('funnels', {
    // a function that returns a fresh state
    state: () => ({
        index: [],
        indexLoaded: false,
        indexLoading: false,
        show: {},
        showLoaded: false,
        showLoading: false,
        filter: {}
    }),
    getters: {
        // // getters receive the state as first parameter
        // doubleCount: (state) => state.counter * 2,
        // // use getters in other getters
        // doubleCountPlusOne() {
        // return this.doubleCount + 1
        // },
    },
    actions: {
        async getIndex() {
            // `this` is the store instance
            this.indexLoading = true;
            let resp = await api.get(`/funnels`)
            this.index = resp.data.data
            this.indexLoading = false;
            this.indexLoaded = true;
        },
        async getShow({funnel}) {
            // `this` is the store instance
            this.showLoading = true;
            let resp = await api.get(`/funnels/${funnel}`)
            this.show = resp.data.data
            this.showLoading = false;
            this.showLoaded = true;
        },
        async saveShow( {funnel, step}) {
            let resp = await api.patch(`/funnels/${funnel}`, this.show)
            this.getShow( { funnel: funnel });
        },
        async addIntegration( { funnel, integration }) {
            // `this` is the store instance
            this.indexLoading = true;
            let resp = await api.post(`/funnels/${funnel}/integrations`, { integration_id: integration.id })
            this.getShow( { funnel: funnel });
            this.indexLoading = false;
        },
        async updateIntegration( { funnel, integration, data }) {
            // `this` is the store instance
            this.indexLoading = true;
            let resp = await api.patch(`/funnels/${funnel}/integrations/${integration}`, data)
            this.getShow( { funnel: funnel });
            this.indexLoading = false;
        },
        async deleteIntegration( { funnel, integration }) {
            // `this` is the store instance
            this.indexLoading = true;
            let resp = await api.delete(`/funnels/${funnel}/integrations/${integration.id}`)
            this.getShow( { funnel: funnel });
            this.indexLoading = false;
        },
        /**
         *
         * @param {*} param0 funnel: id, steps: Boolean
         * @returns
         */
        async resetStats( {funnel, steps}) {
            let resp = await api.post(`/funnels/${funnel}/resetStats`, {steps: steps})
            return true;
        },
        async deleteShow( {funnel, step}) {
            let resp = await api.delete(`/funnels/${funnel}`)
            return true;
        },
    },
})
