<template>
    <div>
        <div class="relative flex items-start">
            <div class="flex h-5 items-center">
                <input
                :value="modelValue"
                @input="inputCb"
                id="validation"
                aria-describedby="validation"
                name="validation"
                :checked="modelValue"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
            </div>
            <div class="ml-3 text-sm">
                <label for="validation" class="font-medium text-gray-700">Valide Daten</label>
                <span id="validation" class="text-gray-500"><span class="sr-only">Prüfen auf valide Formulardaten </span> Sonst ist dieses Element deaktiviert</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    preview: { type: Boolean, default: false, },
    editable: { type: Boolean, default: true, },
    modelValue: {
      type: Boolean,
      default: '',
    },
  },

  methods: {
    inputCb(event) {
        this.$emit('update:modelValue', event.target.checked)
    }
  },

  emits: ['update:modelValue'],
}
</script>

<style>

</style>
