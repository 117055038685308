
<template>
    <div class="space-y-4" v-if="selectedVmodel">
        <Listbox as="div" v-model="selectedVmodel">
            <ListboxLabel class="block text-sm font-medium text-gray-700" v-if="!hideLabel">{{ $t('Actions & Targets') }}</ListboxLabel>
            <div class="relative mt-1">
            <ListboxButton class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                <span class="flex items-center">
                    <span :class="[`bg-${selectedVmodel.type=='step'?'green-400':(selectedVmodel.type=='result'?'orange-400':(selectedVmodel.type==null?'gray-100':'gray-400'))}`, 'inline-block h-2 w-2 flex-shrink-0 rounded-full']" aria-hidden="true" />
                    <span class="ml-3 block truncate">
                        {{ selectedVmodel.type == null ? $t('None') : '' }}
                        {{ selectedVmodel.type == 'step' ? selectedVmodel.slug : '' }}
                        {{ selectedVmodel.type == 'result' ? selectedVmodel.slug : '' }}
                        {{ selectedVmodel.type == 'next' ? $t('Next page') : '' }}
                        {{ selectedVmodel.type == 'href' ? $t('External link') : '' }}
                    </span>
                </span>
                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
            </ListboxButton>

            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <ListboxOption as="template" v-for="choice, index in choices" :key="index" :value="choice" v-slot="{ active, selected }">
                    <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'relative cursor-pointer select-none py-2 pl-3 pr-9']">
                    <div class="flex items-center">
                        <span :class="[`bg-${choice.type=='step'?'green-400':(choice.type=='result'?'orange-400':(choice.type==null?'gray-100':'gray-400'))}`, 'inline-block h-2 w-2 flex-shrink-0 rounded-full']" aria-hidden="true" />
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">
                        {{ choice.type == null ? $t('None') : '' }}
                        {{ choice.type == 'step' ? choice.slug : '' }}
                        {{ choice.type == 'result' ? choice.slug : '' }}
                        {{ choice.type == 'next' ? $t('Next page') : '' }}
                        {{ choice.type == 'href' ? $t('External link') : '' }}
                        </span>
                    </div>

                    <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                    </li>
                </ListboxOption>
                </ListboxOptions>
            </transition>
            </div>
        </Listbox>
        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <div v-show="selectedVmodel.type == 'href'">
                <label for="href" class="block text-sm font-medium text-gray-700">{{ $t('External target') }}</label>
                <div class="mt-1">
                    <input
                    type="text" v-model="selectedVmodel.href"
                    placeholder="https://"
                    name="href" id="href"
                    class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>
const props = defineProps({
    preview: { type: Boolean, default: false, },
    editable: { type: Boolean, default: true, },
    hideLabel: { type: Boolean, default: false, },
    modelValue: { required: true },
})
const emit = defineEmits(['update:modelValue'])

import { computed } from 'vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'

import { useStepsStore } from '@jsResource/stores/steps'
const stepsStore = useStepsStore()
const steps = stepsStore.index;

const choices = [];
choices.push({ type: null, href: null, slug: null, id: null })
choices.push({ type: 'next', href: null, slug: null, id: null })
steps.filter(item => !item.is_result_step).forEach((step) => {
    choices.push({ type: 'step', href: null, slug: step.slug, id: step.id })
})
steps.filter(item => item.is_result_step).forEach((step) => {
    choices.push({ type: 'result', href: null, slug: step.slug, id: step.id })
})
choices.push({ type: 'href', href: null, slug: null, id: null })

const selectedVmodel = computed({
    get() {
        return props.modelValue;
    },
    set(newJump) {
        emit('update:modelValue', newJump)
    }
});
</script>

<style>

</style>
