<template>
    <div>
        <label for="text" class="block text-sm font-medium text-gray-700" v-if="!hideLabel"><slot name="label">Größe</slot></label>
        <span class="isolate inline-flex rounded-md" :class="{ 'mt-1' : !hideLabel }">
            <button @click="inputCb('xs')"
                type="button" v-if="modes.includes('xs')"
                :class="modelValue == 'xs' ? 'border-indigo-300 bg-white text-indigo-700 hover:bg-indigo-50' : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'"
                class="relative inline-flex items-center rounded-l-md border px-4 py-2 text-sm font-medium focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            >
                XS
            </button>
            <button @click="inputCb('s')"
                type="button" v-if="modes.includes('s')"
                :class="modelValue == 's' ?  'border-indigo-300 bg-white text-indigo-700 hover:bg-indigo-50' : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'"
                class="relative -ml-px inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            >
                S
            </button>
            <button @click="inputCb('m')"
                type="button" v-if="modes.includes('m')"
                :class="modelValue == 'm' ?  'border-indigo-300 bg-white text-indigo-700 hover:bg-indigo-50' : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'"
                class="relative -ml-px inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            >
                M
            </button>
            <button @click="inputCb('l')"
                type="button" v-if="modes.includes('l')"
                :class="modelValue == 'l' ?  'border-indigo-300 bg-white text-indigo-700 hover:bg-indigo-50' : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'"
                class="relative -ml-px inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            >
                L
            </button>
            <button @click="inputCb('xl')"
                type="button" v-if="modes.includes('xl')"
                :class="modelValue == 'xl' ? 'border-indigo-300 bg-white text-indigo-700 hover:bg-indigo-50' : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'"
                class="relative -ml-px inline-flex items-center rounded-r-md border px-4 py-2 text-sm font-medium focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            >
                XL
            </button>
        </span>
    </div>
</template>

<script>
export default {
  props: {
    preview: { type: Boolean, default: false, },
    editable: { type: Boolean, default: true, },
    hideLabel: { type: Boolean, default: false },
    modes: { type: Array, default: (['xs','s','m','l','xl']), },
    modelValue: {
      type: String,
      default: '',
    },
  },

  methods: {
    inputCb(value) {
        this.$emit('update:modelValue', value)
    }
  },

  emits: ['update:modelValue'],
}
</script>

<style>

</style>
