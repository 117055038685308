<script setup>
import { Link } from '@inertiajs/vue3';
</script>
<template>
    <div class="text-sm">
        {{ $t('All the settings are made in') }}
        <Link :href="$route('integrations.show', {integration: integration.id})" class="text-indigo-500">{{ $t('Integration Settings') }}</Link>
    </div>
</template>

<script>
import partialMixin from "./partialMixin"
export default {
    mixins: [partialMixin]
}
</script>

<style>

</style>
