<script setup>
import { useForm } from '@inertiajs/vue3';
import JetButton from '@jsResource/Jetstream/Button.vue';
import {getUserFeature} from "../../helper/planHelper.js";
import Upgrade from "./Upgrade.vue";

const props = defineProps({
    type: { default: null },
    integration: { default: null, },
})
let form = useForm({
    name: 'New Domain',
    type: props.type,
    domain: '',
});
if (props.integration) {
    form = useForm({
        name: props.integration.name,
        type: props.integration.type,
        domain: props.integration.data.domain,
    });
}
const submit = () => {
    form.post(route('integrations.store', {type: props.type}), {
        onFinish: () => form.reset('name', 'description'),
    });
};

</script>

<template>
  <div class="mt-10 sm:mt-0">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <h3 class="text-lg font-medium leading-6 text-gray-900">{{$t('Integration details')}}</h3>
          <p class="mt-1 text-sm text-gray-600">{{$t('You are able to update these settings whenever you need to')}}.</p>
        </div>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2">
          <Upgrade v-if="getUserFeature('integrations.'+route().params.type) !== true" />
          <form @submit.prevent="submit" v-else>
          <div class="bg-white border-gray-200 border sm:rounded-md overflow-hidden">
            <div class="px-4 py-5 bg-white sm:p-6">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-6">
                  <label for="name" class="block text-sm font-medium text-gray-700">{{$t('Name')}}</label>
                  <input type="text" v-model="form.name" name="name" id="name" autocomplete="name" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                </div>
                <div class="col-span-6 sm:col-span-6">
                  <label for="domain" class="block text-sm font-medium text-gray-700">{{$t('Domain')}}</label>
                  <input type="text" v-model="form.domain" name="domain" id="domain" placeholder="Domain or Subdomain" autocomplete="domain" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                </div>
                <div class="col-span-6 sm:col-span-6">
                  <label for="domain" class="block text-sm font-medium text-gray-700 mb-2">{{$t('DNS Checks')}}</label>
                  <!-- if ?dns=xyz -->
                  <template v-if="$route().params.dns">
                    <template v-if="$route().params.a_record_correct == 1">
                        <span class="block text-sm text-green-600">{{ $t('A-Record') }}: {{ $t('correctly set up') }}.</span>
                    </template>
                    <template v-else-if="$route().params.a_record_correct == 0">
                        <span class="text-sm block font-bold text-red-600">{{ $t('A-Record') }}: {{ $t('not set up correctly') }}</span>
                        <span v-if="$route().params.a_record_current" class="text-sm font-mono block">
                            Currently: {{ $route().params.a_record_current }}
                        </span>
                        <span v-if="$route().params.a_record_required" class="text-sm font-mono block">
                            Required A: {{ $route().params.a_record_required }}
                        </span>
                    </template>
                    <template v-if="$route().params.aaaa_record_correct == 1">
                        <span class="block text-sm text-green-600">{{ $t('AAAA-Record') }}: {{ $t('correctly set up') }}.</span>
                    </template>
                    <template v-else-if="$route().params.aaaa_record_correct == 0">
                        <span class="text-sm block font-bold text-red-600">{{ $t('AAAA-Record') }}: {{ $t('not set up correctly') }}</span>
                        <span v-if="$route().params.aaaa_record_current" class="text-sm font-mono block">
                            Currently: {{ $route().params.aaaa_record_current }}
                        </span>
                        <span v-if="$route().params.aaaa_record_required" class="text-sm font-mono block">
                            Required AAAA: {{ $route().params.aaaa_record_required }}
                        </span>
                    </template>
                    <div class="block">
                        <a href="https://help.funnerix.com/?s=DNS" target="_blank" class="text-sm cursor-pointer text-indigo-500">{{$t('Need help?')}} {{$t('Go to our helpcenter')}}...</a>
                    </div>
                  </template>
                  <a href="?dns=check" class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    {{$t('Check DNS settings')}}...
                  </a>
                </div>
                <div class="col-span-6 sm:col-span-6">
                  <label for="domain" class="block text-sm font-medium text-gray-700 mb-2">{{$t('SSL Certificate')}}</label>
                  <!-- if ?success=ssl -->
                  <span v-if="$route().params.success" class="text-sm text-green-600">{{$t('The certificate is being created')}}... (ca. 1-2h)</span>
                  <a v-else href="?ssl=renew" class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    {{$t('Recreate SSL Certificate')}}...
                  </a>
                </div>
              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 flex justify-between sm:px-6">
                <div class="pt-2">
                    <span class="mt-1 text-sm text-gray-600" v-if="form.isDirty">{{$t('There are unsaved changes')}}.</span>
                </div>
                <JetButton class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                  {{$t('Save')}}
                </JetButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
