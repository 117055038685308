<template>
    <div>
        <label for="size-range" class="block text-sm font-medium text-gray-700" v-if="!hideLabel"><slot name="label">{{$t('Size range')}}</slot></label>
        <input id="size-range" type="range" :min="min" :max="max" @input="inputCb" :value="modelValue" :step="steps"
        class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer">
    </div>
</template>

<script>
export default {
  props: {
    preview: { type: Boolean, default: false, },
    editable: { type: Boolean, default: true, },
    hideLabel: { type: Boolean, default: false },
    modelValue: {
        type: Number,
        default: 6,
    },
    steps: {
        type: Number,
        default: 1,
    },
    min: {
        type: Number,
        default: 4,
    },
    max: {
        type: Number,
        default: 12,
    },
  },
  methods: {
    inputCb(event) {
        this.$emit('update:modelValue', parseInt(event.target.value));
    },
  },
  emits: ['update:modelValue'],
}
</script>

<style>

</style>
