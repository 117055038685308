<template>
  <div class="mx-auto max-w-lg">
    <h2 class="text-lg font-medium text-gray-900">{{$t('Getting deeper into funnerix')}}</h2>
    <p class="mt-1 text-sm text-gray-500">{{$t('You do not have any funnels in your account yet')}}.</p>
    <ul role="list" class="mt-6 divide-y divide-gray-200 border-t border-b border-gray-200">
      <li v-for="(item, itemIdx) in items" :key="itemIdx">
        <div class="group relative flex items-start space-x-3 py-4">
          <div class="flex-shrink-0">
            <span :class="[item.iconColor, 'inline-flex items-center justify-center h-10 w-10 rounded-lg']">
              <component :is="item.icon" class="h-6 w-6 text-white" aria-hidden="true" />
            </span>
          </div>
          <div class="min-w-0 flex-1">
            <div class="text-sm font-medium text-gray-900">
              <a :href="item.href" :target="item.target" class="group">
                <span class="absolute inset-0" aria-hidden="true" />
                {{ $t(item.name) }}
              </a>
            </div>
            <p class="text-sm text-gray-500">{{ $t(item.description) }}</p>
          </div>
          <div class="flex-shrink-0 self-center">
            <ChevronRightIcon class="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
          </div>
        </div>
      </li>
    </ul>
    <!-- <div class="mt-6 flex">
      <a href="#" class="text-sm font-medium text-indigo-600 hover:text-indigo-500">
        Or start from an empty project
        <span aria-hidden="true"> &rarr;</span>
      </a>
    </div> -->
  </div>
</template>

<script setup>
import { ChevronRightIcon } from '@heroicons/vue/solid'
import { PlusCircleIcon, UserIcon, AcademicCapIcon } from '@heroicons/vue/outline'
import { Link } from '@inertiajs/vue3';
const items = [
    {
        name: 'Create your first funnel',
        description: 'Hop on and create your first funnel',
        href: route('funnels.create'),
        target: null,
        iconColor: 'bg-pink-500',
        icon: PlusCircleIcon,
    },
    {
        name: 'Our Helpcenter',
        description: 'We offer a helpcenter, with every important detail to start with funnerix',
        href: 'https://help.funnerix.com',
        target: '_blank',
        iconColor: 'bg-yellow-500',
        icon: AcademicCapIcon,
    },
    {
        name: 'Profile Management',
        description: 'Invite your friends, upload a profile picture or enable 2FA',
        href: '/user/profile',
        target: null,
        iconColor: 'bg-purple-500',
        icon: UserIcon,
    },
]
</script>
