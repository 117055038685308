<template>
    <div>
        <template v-for="element, index in show.layout" :key="element.id">
            <div @mouseenter="hovering(index)" @mouseleave="hovering(null)"
                :class="hoveringIndex == index ? 'border-indigo-500 ring-1 ring-indigo-500' : 'border-transparent'" class="border rounded-lg">
                <component :is="'modules-partials-'+element.type" :element="element"></component>
            </div>
        </template>
    </div>
</template>

<script setup>
import { useFunnelsStore } from '@jsResource/stores/funnels'
import { useStepsStore } from '@jsResource/stores/steps'
import { useBuilderStore } from '@jsResource/stores/builder'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
const funnelsStore = useFunnelsStore()
const stepsStore = useStepsStore()
const builderStore = useBuilderStore()
const { hoveringIndex } = storeToRefs(builderStore)
stepsStore.getIndex({funnel : route().params.funnel});
const { index, indexLoaded, indexLoading, show, filter } = storeToRefs(stepsStore)
const layout = computed( () => show.layout );
function hovering(index) { builderStore.hoveringBuilderIndex = index }
</script>

<script>

import draggable from "vuedraggable";
export default {
    components: {
        draggable
    },
    methods: {
        log: function(evt) {
            console.log("Previewer");
            console.log(evt);
        },
    }
}
</script>
