import { defineStore } from 'pinia'

// builder is the name of the store. It is unique across your application
// and will appear in devtools
export const useBuilderStore = defineStore('builder', {
    // a function that returns a fresh state
    state: () => ({
        browserTab: 1,                 // 1 = module, 2 = design, 3 = settings, 4 = page
        showSettingsTab: false,        // expand settings
        showIntegrationsTab: false,    // expand integrations management
        editModeSteps: false,          // show remove icon etc.

        showIconbrowserModal: false,   // currently edited step
        iconbrowserModalTabs: [1,2,3], // which tabs should be shown?
        iconbrowserItem: null,         // currently edited module
        iconbrowserTab: 1,

        dragging: false,               // is there a drag drop going on?
        editModule: null,              // currently edited module
        hoveringBuilderIndex: null,
        hoveringIndex: null,
    }),
    getters: {},
    actions: {},
})
