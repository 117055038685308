<script setup>
import { ref } from 'vue';
import { router } from '@inertiajs/vue3';
import { useForm } from '@inertiajs/vue3';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import JetButton from '@jsResource/Jetstream/Button.vue';
import JetFormSection from '@jsResource/Jetstream/FormSection.vue';
import JetInput from '@jsResource/Jetstream/Input.vue';
import JetInputError from '@jsResource/Jetstream/InputError.vue';
import JetLabel from '@jsResource/Jetstream/Label.vue';
import JetActionMessage from '@jsResource/Jetstream/ActionMessage.vue';
import JetActionSection from '@jsResource/Jetstream/ActionSection.vue';
import JetDialogModal from '@jsResource/Jetstream/DialogModal.vue';
import JetDangerButton from '@jsResource/Jetstream/DangerButton.vue';
import JetSecondaryButton from '@jsResource/Jetstream/SecondaryButton.vue';

const props = defineProps({
    funnel: Object,
});

const confirmingFunnelDeletion = ref(false);

const form = useForm({
    _method: 'DELETE',
});

const confirmFunnelDeletion = () => {
    confirmingFunnelDeletion.value = true;
};

const deleteFunnel = () => {
    form.delete(route('funnels.destroy', {funnel: props.funnel}), {
        preserveScroll: true,
        onSuccess: () => closeModal(),
        onError: (e) => { console.log(e) },
        onFinish: () => form.reset(),
    });
};

const closeModal = () => {
    confirmingFunnelDeletion.value = false;
    form.reset();
    // redirect to funnels.index
    router.visit(route('funnels.index'));
};
</script>

<template>
    <JetActionSection>
        <template #title>
            Delete Funnel
        </template>

        <template #description>
            Permanently delete this funnel.
        </template>

        <template #content>

            <div class="max-w-xl text-sm text-gray-600">
                Once your funnel is deleted, all of its resources, steps and data will be permanently deleted.
            </div>

            <div class="mt-5">
                <JetDangerButton @click="confirmFunnelDeletion" v-if="!funnel.is_template">
                    Delete Funnel
                </JetDangerButton>
                <div class="rounded-md bg-yellow-50 p-4" v-else>
                    <div class="flex">
                        <div class="flex-shrink-0">
                        <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                        </svg>
                        </div>
                        <div class="ml-3">
                        <h3 class="text-sm font-medium text-yellow-800">{{$t('Template mode is activated')}}</h3>
                        <div class="mt-2 text-sm text-yellow-700">
                            <p>{{$t('It is not possible to remove a funnel, which is in template mode')}}.</p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Delete Account Confirmation Modal -->
            <JetDialogModal :show="confirmingFunnelDeletion" @close="closeModal">
                <template #title>
                    Delete Funnel
                </template>

                <template #content>
                    Are you sure you want to delete this funnel? Once your funnel is deleted, all of its resources, steps and data will be permanently deleted.
                </template>

                <template #footer>
                    <JetSecondaryButton @click="closeModal">
                        Cancel
                    </JetSecondaryButton>

                    <JetDangerButton
                        class="ml-3"
                        @click="deleteFunnel"
                    >
                        Delete Funnel
                    </JetDangerButton>
                </template>
            </JetDialogModal>
        </template>
    </JetActionSection>
</template>
