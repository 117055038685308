<template>
    <div class="space-y-6 sm:space-y-5">
        <div class="sm:border-t sm:border-gray-200 sm:pt-5" v-bind="getRootProps()">
            <div class="w-full flex justify-center px-6 pt-5 pb-6 rounded-md"
                :class="{ 'border-2 border-purple-300' : isDragActive, 'border-2 border-gray-300 border-dashed' : !isDragActive }">
                <div class="space-y-1 text-center">
                    <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                        <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <div class="flex text-sm text-gray-600">
                        <div class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                            <span>Datei hochladen</span>
                            <input id="file-upload"
                            v-bind="getInputProps()"
                            name="file-upload" type="file" class="sr-only">
                        </div>
                        <p class="pl-1" v-if="isDragActive">und loslassen...</p>
                        <p class="pl-1" v-else>oder ziehen und loslassen</p>
                    </div>
                    <p class="text-xs text-gray-500">PNG, JPG, GIF bis zu 4MB</p>
                </div>
            </div>
        </div>
        <div v-if="expertMode">
            <label for="header" class="block text-sm font-medium text-gray-700">Externe Datei bzw. URL verwenden</label>
            <div class="mt-1">
                <input
                    v-model="element.content.content.src"
                    type="text"
                    name="src"
                    id="src"
                    placeholder="https://"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                >
            </div>
        </div>
    </div>
</template>

<script>
import { usePersistStore } from '@jsResource/stores/persist'
import { storeToRefs } from 'pinia'
import { useDropzone } from "vue3-dropzone";
import { mapStores } from 'pinia'
export default {
    setup( props ) {
        const persistStore = usePersistStore()
        const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });
        const { expertMode } = storeToRefs(persistStore)

        const url = props.apiMediaUrl ?? "/api/media";
        const saveFiles = async (files) => {
            const formData = new FormData(); // pass data as a form
            for (var x = 0; x < files.length; x++) {
                // append files as array to the form, feel free to change the array name
                formData.append("file", files[x]);
            }

            // post the formData to your backend where storage is processed. In the backend, you will need to loop through the array and save each file through the loop.

            try {
                let response = await axios.post(url, formData, { headers: { "Content-Type": "multipart/form-data", }});
                if (response.data && response.data.location) {
                    props.element.content.content.src = response.data.location
                }
            } catch ( err ) {
                console.error(err);
            }
        };

        function onDrop(acceptFiles, rejectReasons) {
            saveFiles(acceptFiles); // saveFiles as callback
            console.log(rejectReasons);
        }

        return {
            getRootProps,
            getInputProps,
            expertMode,
            ...rest,
        };
    },
    props: {
        preview: { type: Boolean, default: false },
        editable: { type: Boolean, default: true },
        element: { type: Object, required: true },
        apiMediaUrl: { type: String },
        modelValue: {
            type: String,
            default: "",
        },
    },

    methods: {
        inputFileCb(event) {
            console.log(event);
        }
    },

    computed: {
        ...mapStores(usePersistStore),
    },

    emits: ["update:modelValue"],
};
</script>

<style>
.row {
    display: flex;
}
.row > * {
    margin: auto;
}
</style>
