<template>
    <TransitionRoot as="template" :show="showIconbrowserModal">
        <Dialog as="div" class="relative z-50" @close="showIconbrowserModal = false" @dragover="iconbrowserTab = 3">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-50 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl md:max-w-2xl sm:p-6">
                    <div>
                        <div class="flex justify-center gap-x-4">
                            <div
                                v-if="iconbrowserModalTabs.includes(1)"
                                @click="iconbrowserTab = 1"
                                :class="{ 'border-purple-500' : iconbrowserTab == 1 }"
                                class="cursor-pointer flex h-12 w-12 items-center border justify-center rounded-full bg-purple-100 hover:bg-purple-300">
                                <ShieldCheckIcon class="h-6 w-6 text-purple-600" aria-hidden="true" />
                            </div>
                            <div
                                v-if="iconbrowserModalTabs.includes(2)"
                                @click="iconbrowserTab = 2"
                                :class="{ 'border-orange-500' : iconbrowserTab == 2 }"
                                class="cursor-pointer flex h-12 w-12 items-center border justify-center rounded-full bg-orange-100 hover:bg-orange-300">
                                <EmojiHappyIcon class="h-6 w-6 text-orange-600" aria-hidden="true" />
                            </div>
                            <div
                                v-if="iconbrowserModalTabs.includes(3)"
                                @click="iconbrowserTab = 3"
                                :class="{ 'border-indigo-500' : iconbrowserTab == 3 }"
                                class="cursor-pointer flex h-12 w-12 items-center border justify-center rounded-full bg-indigo-100 hover:bg-indigo-300">
                                <CloudUploadIcon class="h-6 w-6 text-indigo-600" aria-hidden="true" />
                            </div>
                        </div>
                        <div class="float-right relative">
                            <div class="isolate inline-flex rounded-md" v-if="iconbrowserTab == 1">
                                <div type="button" @click="iconBrowserSet = 'solid'" :class="iconBrowserSet == 'solid' ? 'text-indigo-600' : 'text-gray-700'"
                                    class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                                    Solid
                                </div>
                                <div type="button" @click="iconBrowserSet = 'outline'" :class="iconBrowserSet == 'outline' ? 'text-indigo-600' : 'text-gray-700'"
                                    class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                                    Outline
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 text-center sm:mt-5">
                            <div class="mt-2" v-show="iconbrowserTab == 1">
                                <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">Icon Auswahl</DialogTitle>
                                <div class="overflow-auto h-64">
                                    <div class="grid grid-cols-8 gap-2">
                                        <button v-for="icon, iconName in allHeroIcons[iconBrowserSet]" :key="icon"
                                            @click="selected( 'heroicon', iconBrowserSet, iconName, icon)"
                                            class="h-16 w-16 border-2 rounded-xl text-center cursor-pointer hover:border-indigo-500">
                                            <component :is="icon" class="h-10 w-10 ml-auto mr-auto mt-4 mb-4"></component>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2" v-show="iconbrowserTab == 2">
                                <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">Emoji Auswahl</DialogTitle>
                                <ModulesHelperEmoji @update:modelValue="selectEmoji" />
                            </div>
                            <div class="mt-2" v-show="iconbrowserTab == 3">
                                <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">Bildupload</DialogTitle>
                                <div class="sm:border-t sm:border-gray-200 sm:pt-5" v-bind="getRootProps()">
                                    <div class="w-full flex justify-center px-6 pt-5 pb-6 rounded-md"
                                        :class="{ 'border-2 border-purple-300' : isDragActive, 'border-2 border-gray-300 border-dashed' : !isDragActive }">
                                        <div class="space-y-1 text-center">
                                            <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            <div class="flex text-sm text-gray-600">
                                                <div class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                    <span>Datei hochladen</span>
                                                    <input id="file-upload"
                                                    v-bind="getInputProps()"
                                                    name="file-upload" type="file" class="sr-only">
                                                </div>
                                                <p class="pl-1" v-if="isDragActive">und loslassen...</p>
                                                <p class="pl-1" v-else>oder ziehen und loslassen</p>
                                            </div>
                                            <p class="text-xs text-gray-500">PNG, JPG, GIF bis zu 4MB</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-6">
                        <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                            @click="closeModal()">
                            Keine Änderung...
                        </button>
                    </div>
                    </DialogPanel>
                </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { useDropzone } from "vue3-dropzone";
import { mapStores } from 'pinia'
import { ref } from 'vue'
import { usePersistStore } from '@jsResource/stores/persist'
import { useBuilderStore } from '@jsResource/stores/builder'
import { storeToRefs } from 'pinia'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { DotsHorizontalIcon } from "@heroicons/vue/outline"
import { SelectorIcon, ShieldCheckIcon, EmojiHappyIcon, CloudUploadIcon } from '@heroicons/vue/outline'
import ModulesHelperEmoji from '@jsResource/Funnerix/Modules/Helper/Emoji.vue'

import * as outlineIcons  from '@heroicons/vue/outline'
import * as solidIcons  from '@heroicons/vue/solid'
export default {
    setup(props, context) {
        const allHeroIcons = { outline: outlineIcons, solid: solidIcons };
        const builderStore = useBuilderStore()
        const iconBrowserSet = ref('outline');
        const { showIconbrowserModal, iconbrowserItem, iconbrowserTab, iconbrowserModalTabs } = storeToRefs(builderStore)

        const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

        const url = props.apiMediaUrl ?? "/api/media";
        const saveFiles = async (files) => {
            const formData = new FormData(); // pass data as a form
            for (var x = 0; x < files.length; x++) {
                // append files as array to the form, feel free to change the array name
                formData.append("file", files[x]);
            }

            // post the formData to your backend where storage is processed. In the backend, you will need to loop through the array and save each file through the loop.

            try {
                let response = await axios.post(url, formData, { headers: { "Content-Type": "multipart/form-data", }});
                if (response.data && response.data.location) {
                    console.log(context);
                    selected('image', 'upload', response.data.location);
                } else {
                    console.error("Could not assign a new image")
                }
            } catch ( err ) {
                console.error(err);
            }
        };

        function closeModal() {
            builderStore.showIconbrowserModal = false;
            builderStore.iconbrowserModalTabs = [1,2,3] // reset to initial value
        }

        function selected(iconSet, iconType, iconName, icon = null) {
            console.log("selected", builderStore.iconbrowserItem);
            // update store item ref
            builderStore.iconbrowserItem.iconSet = iconSet
            builderStore.iconbrowserItem.iconType = iconType
            builderStore.iconbrowserItem.iconName = iconName
            //
            closeModal();
            context.emit('selected', { iconSet, iconType, iconName, icon });
        }


        function selectEmoji(emoji) {
            console.log("selectEmoji callback");
            if (typeof emoji !== "Event" && typeof emoji === "object" && typeof emoji.native !== "undefined" && emoji.id != null) {
                console.log(emoji);
                selected('emoji', emoji.id, emoji.native, emoji.unified);
            }
        }

        function onDrop(acceptFiles, rejectReasons) {
            saveFiles(acceptFiles); // saveFiles as callback
            console.log(rejectReasons);
        }

        return {
            allHeroIcons,
            getRootProps,
            getInputProps,
            selected,
            selectEmoji,
            closeModal,
            showIconbrowserModal,
            iconbrowserModalTabs,
            iconbrowserItem,
            iconbrowserTab,
            iconBrowserSet,
            ...rest,
        };
    },
    emits: ['selected'],
    computed: {
        ...mapStores(useBuilderStore, usePersistStore)
    },
    components: {
        Dialog,
        DialogPanel,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        ModulesHelperEmoji,
        SelectorIcon, ShieldCheckIcon, EmojiHappyIcon, CloudUploadIcon
    },
    watch: {
        showIconbrowserModal(newState) {
            if (newState == true) {
                this.iconbrowserTab = this.iconbrowserItem.iconSet == 'emoji' ? 2 : 1;
            }
        }
    },
    props: {
        apiMediaUrl: { type: String },
        showUpload: { type: Boolean, default: true },
        showEmoji: { type: Boolean, default: true },
        showIcons: { type: Boolean, default: true },
    },
}
</script>
