<script setup>
import JetButton from '@jsResource/Jetstream/Button.vue';
</script>
<template>
    <div>
        <label for="header" class="block text-sm font-medium text-gray-700">{{$t('Event Tracking Code')}}</label>
        <div class="mt-1 mb-2">
            <textarea
                v-model="pivot.data.code"
                type="text"
                name="list_id_success"
                id="list_id_success"
                placeholder="!function(f,b,e,v,n,t,s)..."
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
            ></textarea>
        </div>
        <JetButton class="mr-4" @click="save">
            {{$t('Save')}}
        </JetButton>
    </div>
</template>

<script>
import partialMixin from "./partialMixin"
export default {
    mixins: [partialMixin]
}
</script>

<style>

</style>
