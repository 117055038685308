<script setup>
import { Link } from '@inertiajs/vue3';
import { computed } from "vue"
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/vue/solid'
import { CursorClickIcon, MailIcon, UsersIcon, EyeIcon } from '@heroicons/vue/outline'

const props = defineProps({
    funnel: Object,
    options: Object,
});

const stats = [
    {
        id: 1,
        name: 'Total Submissions',
        stat: props.options.totalSubmissions.stat,
        icon: UsersIcon,
        iconBg: 'bg-indigo-500',
        change: props.options.totalSubmissions.change,
        changeType: props.options.totalSubmissions.changeType,
        displayMore: true,
        route: route('responses.index', {funnel: props.funnel.slug})
    },
    {
        id: 2,
        name: 'Conversion Rate',
        stat: props.options.conversionRate.stat+'%',
        icon: MailIcon,
        iconBg: 'bg-teal-500',
        change: props.options.conversionRate.change,
        changeType: props.options.totalSubmissions.changeType,
        displayMore: false,
        route: route('funnels.index')
    },
    {
        id: 3,
        name: 'Total Openings',
        stat: props.options.totalOpenings.stat,
        icon: EyeIcon,
        iconBg: 'bg-green-500',
        change: props.options.totalOpenings.change,
        changeType: props.options.totalOpenings.changeType,
        displayMore: false,
        route: route('funnels.index')
    },
    {
        id: 4,
        name: 'Dropouts',
        stat: props.options.dropouts.stat+'%',
        icon: CursorClickIcon,
        iconBg: 'bg-yellow-500',
        change: props.options.dropouts.change,
        changeType: props.options.dropouts.changeType,
        displayMore: false,
        route: route('funnels.index')
    },
]

import { useFunnelsStore } from '@jsResource/stores/funnels'
const funnelsStore = useFunnelsStore()
const deleteCb = async function() {
  if (confirm("Wirklich alle Statistiken dieses Funnels zurücksetzen?")) {
    await funnelsStore.resetStats({funnel:props.funnel.id, steps: false});
    window.location.reload();
  }
}
</script>

<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <h3 class="text-lg font-medium leading-6 text-gray-900">{{$t('Facts and figures')}} <span class="text-xs">{{$t('Increase during 7 days')}}</span> </h3>

    <dl class="mt-5 grid grid-cols-1 gap-5 2xl:grid-cols-2 mb-5">
      <div v-for="item in stats" :key="item.id" class="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6" :class="item.displayMore ? 'pb-12' : ''">
        <dt>
          <div class="absolute rounded-md p-3" :class="item.iconBg">
            <component :is="item.icon" class="h-6 w-6 text-white" aria-hidden="true" />
          </div>
          <p class="ml-16 truncate text-sm font-medium text-gray-500">{{ $t(item.name) }}</p>
        </dt>
        <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
          <p class="text-2xl font-semibold text-gray-900">{{ item.stat }}</p>
          <p :class="[item.changeType === 'increase' ? 'text-green-600' : 'text-red-600', 'ml-2 flex items-baseline text-sm font-semibold']">
            <ArrowUpIcon v-if="item.changeType === 'increase'" class="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
            <ArrowDownIcon v-else class="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
            <span class="sr-only"> {{ item.changeType === 'increase' ? 'Increased' : 'Decreased' }} by </span>
            {{ item.change }}
          </p>
          <div class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6" v-if="item.displayMore">
            <div class="text-sm">
              <Link :href="item.route" class="font-medium text-indigo-600 hover:text-indigo-500">
                {{$t('View more details')}}
              </Link>
            </div>
          </div>
        </dd>
      </div>
    </dl>

    <div class="overflow-hidden rounded-lg bg-white shadow">
      <div class="px-4 py-5 sm:p-6">
        <p>
          {{$t('To reset your stats please click')}}
          <a href="#delete" @click.stop="deleteCb" class="text-red-900 hover:text-red-600">{{$t('here')}}</a>.
        </p>
      </div>
    </div>
  </div>
</template>
