import axios from "axios";

const api = axios.create({
    withCredentials: true,
    baseURL: '/api',
});

api.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
api.defaults.headers.common['Accept'] = 'application/json';
api.defaults.headers.common['X-Funnerix-API'] = 'Welcome';
api.defaults.withCredentials = true;
api.defaults.withXSRFToken = true;

export default api;
