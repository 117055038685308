<template>
    <div class="space-y-6 sm:space-y-5">
        <modules-helper-text v-model="theContent.header"><template #label>{{$t('Title')}}</template></modules-helper-text>
        <modules-helper-text v-model="theContent.subline"><template #label>{{$t('Subline')}}</template></modules-helper-text>
        <modules-helper-text :multiline="true" v-model="theContent.label"><template #label>{{$t('Label')}}</template></modules-helper-text>
        <modules-helper-text :multiline="false" v-model="theContent.placeholder"><template #label>{{$t('Placeholder')}}</template></modules-helper-text>
        <div v-if="theContent.type">
            <label for="type" class="block text-sm font-medium text-gray-700">{{$t('Input type')}}</label>
            <select id="type" v-model="theContent.type" name="type" class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                <option value="text">{{$t('Text input')}}</option>
                <option value="email">{{$t('Email')}}</option>
                <option value="datetime-local">{{$t('Date')}}</option>
                <option value="number">{{$t('Numbers')}}</option>
            </select>
        </div>

        <div class="grid grid-cols-2 gap-x-2">
            <modules-helper-colors v-model="element.content.style.backgroundColor"><template #label>{{$t('Background Color')}}</template></modules-helper-colors>
            <modules-helper-colors v-model="element.content.style.color"><template #label>{{$t('Color')}}</template></modules-helper-colors>
        </div>
        <div class="grid grid-cols-2 gap-x-2">
            <modules-helper-padding v-model="theContent.paddingTop"><template #label>{{$t('Margin top')}}</template></modules-helper-padding>
            <modules-helper-padding v-model="theContent.paddingBottom"><template #label>{{$t('Margin bottom')}}</template></modules-helper-padding>
        </div>
        <modules-helper-id-name :element="element"></modules-helper-id-name>
    </div>
</template>

<script>
import modulesMixin from "../modulesMixin";
export default {
    mixins: [ modulesMixin ],
    methods: {
        removeItem(index) {
            this.theContent.items.splice(index, 1);
        }
    }
}
</script>

<style>

</style>
