<template>
    <div class="bg-white shadow rounded-md">
        <div class="mx-auto max-w-2xl py-4 px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
            <h2 class="text-2xl font-bold tracking-tight text-gray-900">{{$t('What do you want to build')}}?</h2>
            <div class="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                <div v-for="funnelTemplate in funnelTemplates" :key="funnelTemplate.id" @click="selectTemplate(funnelTemplate)" class="cursor-pointer h-48 group relative rounded-md p-4 hover:scale-105 hover:shadow-lg transition-all"
                :class="'bg-gradient-to-br from-10% from-'+(funnelTemplate.template_gradient_from ?? 'indigo')+'-500 via-30% via-'+(funnelTemplate.template_gradient_over ?? 'purple')+'-500 to-90% to-'+(funnelTemplate.template_gradient_to ?? 'pink')+'-500'">
                    <div class="flex w-full justify-end mb-6">
                        <jetstream-tooltip :position="'left'" :content="$t('Funnerix Team')" :bg="'bg-gray-800'" :offset="20">
                            <template #content>
                                <div class="text-white p-3">Funnerix Team</div>
                            </template>
                            <span class="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
                                <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                </svg>
                            </span>
                        </jetstream-tooltip>
                    </div>
                    <div class="transition-all min-h-12 w-full overflow-hidden rounded-md">
                        <template v-if="funnelTemplate && funnelTemplate.template_icon && funnelTemplate.template_icon.iconSet">
                            <component v-if="funnelTemplate.template_icon.iconSet == 'heroicon' && allHeroIcons[funnelTemplate.template_icon.iconType] && allHeroIcons[funnelTemplate.template_icon.iconType][funnelTemplate.template_icon.iconName]"
                                class="text-white w-6 h-6 inline" :is="allHeroIcons[funnelTemplate.template_icon.iconType][funnelTemplate.template_icon.iconName]"></component>
                            <span v-else-if="funnelTemplate.template_icon.iconSet == 'emoji'">{{funnelTemplate.template_icon.iconName}}</span>
                            <component :is="UsersIcon" class="h-6 w-6 text-white" aria-hidden="true" v-else />
                        </template>
                        <template v-else>
                            <component :is="CubeIcon" class="h-6 w-6 text-white" aria-hidden="true" />
                        </template>
                    </div>
                    <div class="mt-4 flex justify-between">
                        <div>
                            <h3 class="text-white text-xl">
                                <div>
                                    <!-- <span aria-hidden="true" class="absolute inset-0" />  -->
                                    {{ $t(funnelTemplate.name) }}
                                </div>
                            </h3>
                            <p class="mt-1 text-sm text-gray-100" v-if="funnelTemplate.description">{{ $t(funnelTemplate.description) }}</p>
                        </div>
                    </div>
                </div>
                <!-- Empty -->
                <div @click="selectTemplate(null)" class="cursor-pointer relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <svg class="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6" />
                    </svg>
                    <span class="mt-2 block text-sm font-medium text-gray-900">{{$t('Start empty')}}...</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { UsersIcon, QuestionMarkCircleIcon, ViewGridIcon, CubeIcon } from "@heroicons/vue/outline"
import JetstreamTooltip from "@jsResource/Jetstream/Tooltip.vue"

import * as outlineIcons  from '@heroicons/vue/outline'
import * as solidIcons  from '@heroicons/vue/solid'
const allHeroIcons = { outline: outlineIcons, solid: solidIcons };

const emit = defineEmits(['selectedTemplate'])
const props = defineProps({
    funnelTemplates: Array
})

const selectTemplate = (template) => {
    emit('selectedTemplate', template)
}
</script>
