<script setup>
import { useForm } from '@inertiajs/vue3';
import JetButton from '@jsResource/Jetstream/Button.vue';
import {getUserFeature} from "../../helper/planHelper.js";
import Upgrade from "./Upgrade.vue";

const props = defineProps({
    type: { default: null },
    integration: { default: null, },
})
let form = useForm({
    name: 'Meta Pixel',
    type: props.type,
    pixel_id: '',
});
if (props.integration) {
    form = useForm({
        name: props.integration.name,
        type: props.integration.type,
        pixel_id: props.integration.data.pixel_id,
    });
}
const submit = () => {
    form.post(route('integrations.store', {type: props.type}), {
        onFinish: () => form.reset('name', 'description'),
    });
};

</script>

<template>
  <div class="mt-10 sm:mt-0">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <h3 class="text-lg font-medium leading-6 text-gray-900">{{$t('Integration details')}}</h3>
          <p class="mt-1 text-sm text-gray-600">{{$t('You are able to update these settings whenever you need to')}}.</p>
        </div>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2">
          <Upgrade v-if="getUserFeature('integrations.'+route().params.type) !== true" />
          <form @submit.prevent="submit" v-else>
          <div class="bg-white border-gray-200 border sm:rounded-md overflow-hidden">
            <div class="px-4 py-5 bg-white sm:p-6">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-6">
                  <label for="name" class="block text-sm font-medium text-gray-700">{{$t('Name')}}</label>
                  <input type="text" v-model="form.name" name="name" id="name" autocomplete="name" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                </div>
                <div class="col-span-6 sm:col-span-6">
                  <label for="pixel_id" class="block text-sm font-medium text-gray-700">{{$t('Pixel ID')}}</label>
                  <input type="text" v-model="form.pixel_id" name="pixel_id" id="pixel_id" placeholder="0123456789" autocomplete="pixel_id" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                </div>
              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 flex justify-between sm:px-6">
                <div class="pt-2">
                    <span class="mt-1 text-sm text-gray-600" v-if="form.isDirty">{{$t('There are unsaved changes')}}.</span>
                </div>
                <JetButton class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                  {{$t('Save')}}
                </JetButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
