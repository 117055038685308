<script setup>
import {computed} from "vue";

const props = defineProps({
    type: {
        type: String,
        default: 'submit',
    },
    href: {
        type: String,
        required: false,
        default: null,
    },
    variant: {
        type: String,
        default: 'primary',
    },
});

const classes = computed(() => {
    let classes = [
        'inline-flex items-center px-4 py-2 border border-transparent rounded-md font-semibold',
        'text-xs uppercase tracking-widest focus:outline-none focus:ring disabled:opacity-25 transition',
    ];
    // variant primary, secondary and error
    if (props.variant === 'primary') {
        classes.push('bg-gray-800 focus:border-gray-900 focus:ring-gray-300 active:bg-gray-900 hover:bg-gray-700 text-white');
    } else if (props.variant === 'secondary') {
        classes.push('bg-gray-600 focus:border-gray-700 focus:ring-gray-300 active:bg-gray-700 hover:bg-gray-500 text-white');
    } else if (props.variant === 'error') {
        classes.push('bg-red-600 focus:border-red-700 focus:ring-red-300 active:bg-red-700 hover:bg-red-500 text-white');
    }
    return classes
});

const theTag = computed(() => {
    return props.href ? 'a' : 'button';
});
</script>

<template>
    <component :is="theTag" :type="type" :href="href" :class="classes">
        <slot />
    </component>
</template>
