<script setup>
import JetButton from '@jsResource/Jetstream/Button.vue';
</script>
<template>
    <div>
        <label for="header" class="block text-sm font-medium text-gray-700">{{$t('List ID after successful submission')}}</label>
        <div class="mt-1 mb-2">
            <input
                type="text"
                name="list_id"
                id="list_id"
                placeholder="123"
                v-model="pivot.data.list_id"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
            >
        </div>
        <label for="header" class="block text-sm font-medium text-gray-700">{{$t('Tags after successful submission')}}</label>
        <div class="mt-1 mb-2">
            <input
                type="text"
                name="tags"
                id="tags"
                v-model="pivot.data.tags"
                :placeholder="'tag1,tag2,tag3 '+$t('Tags applied after a successful submission')"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
            >
        </div>
        <label for="header" class="block text-sm font-medium text-gray-700">{{$t('Form ID after successful submission')}}</label>
        <div class="mt-1 mb-2">
            <input
                type="text"
                name="form_id"
                id="form_id"
                v-model="pivot.data.form_id"
                :placeholder="'123 ('+$t('optional')+')'"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
            >
        </div>
        <JetButton class="mr-4" @click="save">
            {{$t('Save')}}
        </JetButton>
    </div>
</template>

<script>
import partialMixin from "./partialMixin"
export default {
    mixins: [partialMixin]
}
</script>

<style>

</style>
